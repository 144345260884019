import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import CustomDropdown from "../components/CustomDropdown";
import { translationNavigation } from "../utils/translations";

const Sidebar = ({ isAuthenticated, setIsAuthenticated, chatbotIds, isAdmin, selectedChatbotId, setSelectedChatbotId, isSidebarOpen, setIsSidebarOpen }) => {
  const sidebarRef = useRef(null);
  const showPurchase = localStorage.getItem("show_purchase") === "true";
  const storedLanguage = localStorage.getItem("appLanguage") || "da";
  const [appLanguage, setAppLanguage] = useState(storedLanguage);
  const translations = translationNavigation;
  const t = (key) => translations[appLanguage]?.[key] || key;
  const location = useLocation();

  const handleLogout = () => { 
      localStorage.removeItem('token');
      localStorage.removeItem('chatbot_ids');
      localStorage.removeItem('show_purchase');
      localStorage.removeItem('selected_chatbot_id');
      setIsAuthenticated(false);
      window.location.href = '/login';
  };

  const handleLanguageChange = (value) => {
    localStorage.setItem("appLanguage", value);
    setAppLanguage(value);
    window.location.reload();
  };

  const languageOptions = [
    { value: 'da', label: 'Dansk', image: '/pngs/denmark.png' },
    { value: 'sv', label: 'Svenska', image: '/pngs/sweden.png' },
    { value: 'no', label: 'Norsk', image: '/pngs/norway.png' },
    { value: 'en', label: 'English', image: '/pngs/united.png' },
  ];

  const handleChatbotChange = (value) => {
    setSelectedChatbotId(value);
    localStorage.setItem("selected_chatbot_id", value);
    window.location.reload();
  };

  const chatbotOptions = [{ value: "ALL", label: t("allChatbots"), image: "/svgs/all-chat.svg" }, ...chatbotIds.map(id => ({ value: id, label: id, image: "/svgs/all-chat.svg" }))];

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      const shouldBeSmallScreen = window.innerWidth <= 1024;
      setIsSmallScreen(shouldBeSmallScreen);
      // Automatically close the sidebar on small screens and open it on larger screens
      if (shouldBeSmallScreen) {
        setIsSidebarOpen(false); // Close sidebar on small screens
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    handleResize(); // Call on mount to set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsSidebarOpen]);

  const sidebarStyle = {
    width: isSidebarOpen ? '250px' : '104px',
    transition: 'width 0.3s ease', // Add transition for large screens
  };

  const smallScreenSidebarStyle = {
    ...sidebarStyle,
    transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 50,
    transition: 'transform 0.3s ease, width 0.3s ease', // Add transition for small screens
  };

  useEffect(() => {
    if (isSmallScreen) {
      setIsSidebarOpen(false);
    }

    if (location.pathname === "/upload-data") {
      setIsSidebarOpen(false);
    }
  }, [location, isSmallScreen, setIsSidebarOpen]);

  // Function to handle dropdown click and open sidebar
  const handleDropdownClick = () => {
    if (!isSidebarOpen) {
      setIsSidebarOpen(true);
    }
  };

  return (
    <div className="flex">
      {isSmallScreen && isSidebarOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}

      <div
        ref={sidebarRef}
        className={`bg-[#212023] text-white h-screen overflow-y-auto overflow-x-hidden pb-5 px-5 pt-8 flex flex-col ${isSidebarOpen ? "w-[250px]" : "w-[104px]"} `}
        style={isSmallScreen ? smallScreenSidebarStyle : sidebarStyle}
      >
        <div className="flex justify-between items-center mb-10">
          <img
            src="/pngs/logo.png"
            alt="Logo"
            className={`w-[153px] ${isSidebarOpen ? '' : 'hidden'}`}
          />
          <img
            src="/pngs/logo-small.png"
            alt="Logo"
            className={`w-14 h-14 ${isSidebarOpen ? 'hidden' : 'block'}`}
          />
          <img
            src={isSmallScreen ? "/svgs/charm_cross.svg" : "/svgs/toggle-sidebar.svg"}
            alt="toggle"
            className="w-6 h-6 cursor-pointer"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          />
        </div>

        {/* Chatbot Selector */}
        {isAuthenticated && chatbotIds.length > 1 && (
          <div onClick={handleDropdownClick}>
            <CustomDropdown
              value={selectedChatbotId}
              options={chatbotOptions}
              onChange={handleChatbotChange}
              isCenter={!isSidebarOpen}
              placeholder={t("allChatbots")}
              renderItem={(item) => (
                <div className="flex items-center space-x-2">
                  <img src={item.image} alt={item.label} className={`w-6 h-6`} />
                  <span className={`text-xs ${isSidebarOpen ? '' : 'hidden'}`}>{item.label}</span>
                </div>
              )}
              isHidden={!isSidebarOpen}
            />
          </div>
        )}

        {/* Navigation Links */}
        <nav className="flex flex-col mt-[10px] space-y-[10px] flex-grow">
          <NavLink
            to="/"
            className={({ isActive }) => `relative p-4 rounded-2xl gap-3 text-[#FFFFFF] ${isSidebarOpen ? 'justify-start' : 'justify-center'} text-xs font-normal flex items-center ${isActive ? 'bg-[#686BF1] shadow-[0px_8px_16px_0px_#686BF129]' : 'hover:bg-gray-700'}`}
          >
            <div className="relative">
              <img src="/svgs/message.svg" alt="..." className="w-6 h-6" />
              {/* <div className={`absolute -top-2.5 -right-2.5 h-[22px] w-[22px] rounded-full bg-[#FF7851] text-white text-xs font-normal flex justify-center items-center ${isSidebarOpen ? 'hidden' : 'block'}`}>
                44
              </div> */}
            </div>
            <span className={`opacity-70 ${isSidebarOpen ? '' : 'hidden'}`}>{t("conversations")}</span>
            {/* <div className={`h-[22px] min-w-8 rounded-[30px] bg-[#FF7851] ${isSidebarOpen ? '' : 'hidden'} text-white text-xs font-normal flex justify-center items-center`}>
              44
            </div> */}
          </NavLink>

          <NavLink
            to="/upload-data"
            className={({ isActive }) => `p-4 gap-3 text-[#FFFFFF] text-xs font-normal flex ${isSidebarOpen ? 'justify-start' : 'justify-center'} items-center rounded-2xl ${isActive ? 'bg-[#686BF1] shadow-[0px_8px_16px_0px_#686BF129]' : 'hover:bg-gray-700'}`}
          >
            <img src="/svgs/upload.svg" alt="..." className="w-6 h-6" />
            <span className={`opacity-70 ${isSidebarOpen ? '' : 'hidden'}`}>{t("uploadData")}</span>
          </NavLink>

          <NavLink
            to="/statistics"
            className={({ isActive }) => `p-4 gap-3 text-[#FFFFFF] text-xs font-normal flex ${isSidebarOpen ? 'justify-start' : 'justify-center'} items-center rounded-2xl ${isActive ? 'bg-[#686BF1] shadow-[0px_8px_16px_0px_#686BF129]' : 'hover:bg-gray-700'}`}
          >
            <img src="/svgs/stats.svg" alt="..." className="w-6 h-6" />
            <span className={`opacity-70 ${isSidebarOpen ? '' : 'hidden'}`}>{t("statistics")}</span>
          </NavLink>

          <NavLink
            to="/chatbot"
            className={({ isActive }) => `p-4 gap-3 text-[#FFFFFF] text-xs font-normal flex ${isSidebarOpen ? 'justify-start' : 'justify-center'} items-center rounded-2xl ${isActive ? 'bg-[#686BF1] shadow-[0px_8px_16px_0px_#686BF129]' : 'hover:bg-gray-700'}`}
          >
            <img src="/svgs/chatbot.svg" alt="..." className="w-6 h-6" />
            <span className={`opacity-70 ${isSidebarOpen ? '' : 'hidden'}`}>{t("Chatbot")}</span>
          </NavLink>

          {showPurchase && (
            <NavLink
              to="/crm"
              className={({ isActive }) => `p-4 gap-3 text-[#FFFFFF] text-xs font-normal flex ${isSidebarOpen ? 'justify-start' : 'justify-center'} items-center rounded-2xl ${isActive ? 'bg-[#686BF1] shadow-[0px_8px_16px_0px_#686BF129]' : 'hover:bg-gray-700'}`}
            >
              <img src="/svgs/chatbot.svg" alt="..." className="w-6 h-6" />
              <span className={`${isSidebarOpen ? '' : 'hidden'}`}>{t("Crm")}</span>
            </NavLink>
          )}

          {isAdmin && (
            <NavLink
              to="/users"
              className={({ isActive }) => `p-4 gap-3 text-[#FFFFFF] text-xs font-normal flex ${isSidebarOpen ? 'justify-start' : 'justify-center'} items-center rounded-2xl ${isActive ? 'bg-[#686BF1] shadow-[0px_8px_16px_0px_#686BF129]' : 'hover:bg-gray-700'}`}
            >
              <img src="/svgs/chatbot.svg" alt="..." className="w-6 h-6" />
              <span className={`${isSidebarOpen ? '' : 'hidden'}`}>{t("users")}</span>
            </NavLink>
          )}
        </nav>

        {/* Language Selector and Logout*/}
        {isAuthenticated && (
          <div className="mt-auto flex flex-col space-y-4">
            <div onClick={handleDropdownClick}>
              <CustomDropdown
                value={appLanguage}
                options={languageOptions}
                onChange={handleLanguageChange}
                placeholder="Language"
                isHidden={!isSidebarOpen}
                isCenter={!isSidebarOpen}
                renderItem={(item) => (  // Modified renderItem
                  <div className="flex items-center space-x-2">
                    <img src={item.image} alt={item.label} className={`w-6 h-6`} />
                    <span className={`text-xs ${isSidebarOpen ? '' : 'hidden'}`}>{item.label}</span>
                  </div>
                )}
              />
            </div>

            <button onClick={handleLogout} className={`flex ${isSidebarOpen ? 'justify-start' : 'justify-center'} text-[#FFFFFF] text-xs px-4 font-normal items-center gap-3`}>
              <img src="/svgs/logout.svg" alt="logout" className="w-6 h-6" />
              <span className={`opacity-70 ${isSidebarOpen ? '' : 'hidden'}`}>{t("logout")}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;