import React, { useEffect, useRef, useState } from "react";

const TabGroup = ({ tabsArray, onTabChange, activeTab, setActiveTab, tabWidth }) => {
    const tabRefs = useRef({});
    const containerRef = useRef(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [indicatorStyle, setIndicatorStyle] = useState({ left: "0px", width: "0px" });

    useEffect(() => {
        onTabChange(activeTab);

        if (!isInitialLoad && tabRefs.current[activeTab]) {
            const container = containerRef.current;
            const activeTabElement = tabRefs.current[activeTab];

            if (container && activeTabElement) {
                const tabLeft = activeTabElement.offsetLeft;
                const tabWidth = activeTabElement.offsetWidth;
                const containerScrollLeft = container.scrollLeft;
                const containerWidth = container.offsetWidth;

                if (tabLeft < containerScrollLeft || tabLeft + tabWidth > containerScrollLeft + containerWidth) {
                    container.scrollTo({
                        left: tabLeft - containerWidth / 2 + tabWidth / 2,
                        behavior: "smooth",
                    });
                }
            }
        }

        setIsInitialLoad(false);
    }, [activeTab, onTabChange]); // Added onTabChange to dependency array to remove linting warning

    useEffect(() => {
        const activeTabElement = tabRefs.current[activeTab];
        if (activeTabElement) {
            setIndicatorStyle({
                left: `${activeTabElement.offsetLeft}px`,
                width: `${activeTabElement.offsetWidth}px`,
            });
        }
    }, [activeTab]);

    const handleTabClick = (tab) => {
        if (tab !== activeTab) {
            setActiveTab(tab);
        }
    };

    return (
        <div ref={containerRef} className={`relative flex bg-[#EEEFFA] rounded-full p-[6px] overflow-x-auto w-auto hide-scrollbar m-auto ${tabWidth}`}>
            <div
                className="absolute top-1/2 -translate-y-1/2 h-[80%] bg-[#686BF1] rounded-full transition-all duration-300"
                style={{
                    left: indicatorStyle.left,
                    width: indicatorStyle.width,
                }}
            />

            {tabsArray.map((tab) => (
                <button
                    key={tab}
                    ref={(el) => (tabRefs.current[tab] = el)}
                    className={`relative flex-1 text-center py-3 px-4 text-xs rounded-full font-light transition-all duration-300 ${
                        activeTab === tab ? "text-white" : "text-[#212023]"
                    }`}
                    style={{ textTransform: "capitalize" }}
                    onClick={() => handleTabClick(tab)}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};

export default TabGroup;