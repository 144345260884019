import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import DatePicker from 'react-datepicker';
import { format, subHours } from 'date-fns';
import 'react-multi-carousel/lib/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select'; // Import react-select
import TabGroup from '../../components/TabsGroup';
import { translationUploadData } from '../../utils/translations';
import { responsiveScreen } from '../../utils/general';
import ConversationCard from '../../components/ConversationCard';
import InputField from '../../components/InputField';
import Modal from '../../components/Modal';
import { Skeleton, Tooltip } from 'antd';

const translations = translationUploadData
const responsive = responsiveScreen;

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        fontSize: '14px', // Adjust font size to 14px
    }),
};

function UploadData() {
    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('appLanguage') || 'da';
    });

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'appLanguage') {
                setLanguage(e.newValue || 'da');
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const t = (key) => translations[language]?.[key] || key;

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [expirationDate, setExpirationDate] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [message, setMessage] = useState('');
    const [indexes, setIndexes] = useState([]);
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [view, setView] = useState('uploadedData');
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [expandedItems, setExpandedItems] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMarkingResolved, setIsMarkingResolved] = useState(false);
    const [isEditing, setIsEditing] = useState(isMarkingResolved);
    const [editingEntries, setEditingEntries] = useState([]);
    const [showConversationDetails, setShowConversationDetails] = useState(false);
    const [API_URL] = useState('https://egendatabasebackend.onrender.com'); // Use useState for const-correctness
    const [showEditModal, setShowEditModal] = useState(false); // State to control the modal visibility
    const [showUploadModal, setShowUploadModal] = useState(false); // State for upload modal visibility on small screens

    // Using useRef to prevent closing Modal twice
    const isModalOpen = useRef(false);
    const datePickerRef = useRef(null);

    const [focusedField, setFocusedField] = useState(null);

    const handleFocus = useCallback((fieldId) => {
        setFocusedField(fieldId);
    }, []);

    const isFieldFocused = useCallback((fieldId) => {
        return focusedField === fieldId;
    }, [focusedField]);

    useEffect(() => {
        fetchIndexes();
    }, []);

    const toggleExpand = (idx) => {
        setExpandedItems((prev) => ({
            ...prev,
            [idx]: !prev[idx],
        }));
    };


    useEffect(() => {
        if (view === 'uploadedData') {
            fetchData();
        } else if (view === 'lackingInfoConversations') {
            fetchConversations();
        }
    }, [view]);

    const fetchIndexes = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/pinecone-indexes`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setIndexes(response.data);
        } catch (error) {
            setMessage(t('errorFetchingIndexes'));
        }
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/pinecone-data`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const groupedData = response.data.reduce((acc, item) => {
                const {
                    title,
                    text,
                    id,
                    pinecone_index_name,
                    namespace,
                    expiration_time,
                } = item;

                if (!acc[text]) {
                    acc[text] = { title, text, entries: [] };
                }
                acc[text].entries.push({
                    id,
                    pinecone_index_name,
                    namespace,
                    expiration_time,
                });
                return acc;
            }, {});
            setDataList(Object.values(groupedData));
            setMessage('');
        } catch (error) {
            setMessage(t('errorFetchingData'));
        } finally {
            setIsLoading(false);
        }
    };

    const fetchConversations = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                setMessage(t('tokenNotFound'));
                return;
            }
            const storedChatbotIds = localStorage.getItem('chatbot_ids');
            const chatbotIds = storedChatbotIds ? JSON.parse(storedChatbotIds) : [];
            const chosenId = localStorage.getItem('selected_chatbot_id') || 'ALL';
            if (chatbotIds.length === 0) {
                setMessage(t('tokenChatbotMissing'));
                return;
            }
            let finalID = '';
            if (chosenId === 'ALL') {
                finalID = chatbotIds.join(',');
            } else {
                finalID = chosenId;
            }
            if (!finalID) {
                setMessage(t('tokenChatbotMissing'));
                return;
            }
            const response = await axios.get(`${API_URL}/conversations`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    chatbot_id: finalID,
                    lacking_info: 'true',
                },
            });
            setConversations(response.data);
            setMessage('');
        } catch (error) {
            setMessage(t('errorConversations'));
        } finally { setIsLoading(false); }
    };

    const markConversationResolved = async () => {
        if (!selectedConversation) return;
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setMessage(t('tokenNotFound'));
                return;
            }
            setIsMarkingResolved(true);
            await axios.patch(
                `${API_URL}/conversations/${selectedConversation.id}`,
                { lacking_info: false },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessage(t('convoResolved'));
            fetchConversations();
        } catch (error) {
            setMessage(t('errorUpdatingConvo'));
        } finally {
            setIsMarkingResolved(false);
        }
    };

    const handleUpload = async () => {
        if (!title.trim()) {
            setMessage(t('Title is Required'));
            return;
        }
        if (title.length > 255) {
            setMessage(t('titleLimit'));
            return;
        }

        if (!isEditing) {
            if (selectedIndexes.length === 0) {
                setMessage(t('Index is Required'));
                return;
            }
        }

        const token = localStorage.getItem('token');
        if (!token) {
            setMessage(t('tokenNotFound'));
            return;
        }

        setIsUploading(true);

        try {
            let formattedExpirationTime;
            if (expirationDate) {
                const adjustedDate = subHours(expirationDate, 1);
                formattedExpirationTime = format(adjustedDate, 'yyyy-MM-dd HH:mm:ss');
            }

            if (isEditing) {
                const successMessages = [];
                const errorMessages = [];
                const entryMap = {};

                editingEntries.forEach((entry) => {
                    const key = `${entry.pinecone_index_name}:${entry.namespace}`;
                    entryMap[key] = entry.id;
                });

                for (const { index_name, namespace } of selectedIndexes) {
                    const key = `${index_name}:${namespace}`;
                    const entryId = entryMap[key];
                    if (entryId) {
                        try {
                            await axios.put(
                                `${API_URL}/pinecone-data-update/${entryId}`,
                                { title, text },
                                { headers: { Authorization: `Bearer ${token}` } }
                            );
                            successMessages.push(`${t('dataUpdated')} ${index_name}`);
                        } catch (error) {
                            errorMessages.push(`${t('errorUpdating')} ${index_name}`);
                        }
                    } else {
                        try {
                            await axios.post(
                                `${API_URL}/pinecone-data`,
                                {
                                    title,
                                    text,
                                    indexName: index_name,
                                    namespace,
                                    expirationTime: formattedExpirationTime || undefined,
                                },
                                { headers: { Authorization: `Bearer ${token}` } }
                            );
                            successMessages.push(`${t('dataAdded')} ${index_name}`);
                        } catch (error) {
                            errorMessages.push(`${t('errorAdding')} ${index_name}`);
                        }
                    }
                }

                for (const entry of editingEntries) {
                    const stillSelected = selectedIndexes.find(
                        (idx) =>
                            idx.index_name === entry.pinecone_index_name &&
                            idx.namespace === entry.namespace
                    );
                    if (!stillSelected) {
                        try {
                            await axios.delete(`${API_URL}/pinecone-data-update/${entry.id}`, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            successMessages.push(`${t('dataDeletedFrom')} ${entry.pinecone_index_name}`);
                        } catch (error) {
                            errorMessages.push(`${t('errorDeletingFrom')} ${entry.pinecone_index_name}`);
                        }
                    }
                }

                setMessage([...successMessages, ...errorMessages].join('. '));
                setText('');
                setTitle('');
                setExpirationDate(null);
                setIsEditing(false);
                setEditingEntries([]);
                fetchData();
            } else {
                const successMessages = [];
                const errorMessages = [];
                for (const { value, label } of selectedIndexes) {
                    try {
                        await axios.post(
                            `${API_URL}/pinecone-data`,
                            {
                                title,
                                text,
                                indexName: label,
                                namespace: value,
                                expirationTime: formattedExpirationTime || undefined,
                            },
                            { headers: { Authorization: `Bearer ${token}` } }
                        );
                        successMessages.push(`${t('dataUploaded')} ${label}`);
                    } catch (error) {
                        errorMessages.push(`${t('errorUploadingTo')} ${label}`);
                    }
                }

                setMessage([...successMessages, ...errorMessages].join('. '));
                setText('');
                setTitle('');
                setExpirationDate(null);
                fetchData();
                if (selectedConversation) {
                    try {
                        setIsMarkingResolved(true);
                        await axios.patch(
                            `${API_URL}/conversations/${selectedConversation.id}`,
                            { lacking_info: false },
                            { headers: { Authorization: `Bearer ${token}` } },
                        );
                        setMessage((prev) => prev + t('convoMarkedResolved'));
                        fetchConversations();
                        setSelectedConversation(null);
                    } catch (error) {
                        setMessage(t('errorUpdatingConvo'));
                    } finally {
                        setIsMarkingResolved(false);
                    }
                }
            }
            setIsEditing(false);
            setEditingEntries(null);
            fetchData();
        } catch (error) {
            setMessage(t('errorUpdatingData'));
        } finally {
            setIsUploading(false);
        }
    };

    const handleEdit = (entries, existingTitle, existingText) => {
        setTitle(existingTitle);
        setText(existingText);
        setIsEditing(true);
        setEditingEntries(entries);

        setSelectedIndexes(
            entries.map((entry) => ({
                label: entry.pinecone_index_name,
                value: entry.namespace,
            }))
        );
        if (window.innerWidth < 1024) {
            setShowEditModal(true);
        }
    };

    const handleIndexSelection = (selectedOptions) => {
        setSelectedIndexes(selectedOptions || []);
    };

    const handleDelete = async (id, indexName) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_URL}/pinecone-data/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage(`${t('dataDeletedMessage')} ${indexName}!`);
            fetchData();
        } catch (error) {
            setMessage(`${t('errorDeletingMessage')} ${indexName}.`);
        }
    };

    const handleCardClick = (item) => {
        setSelectedItem(item);
    };
    const handleConversationClick = (conversation) => {
        setSelectedConversation(conversation);
        setShowConversationDetails(true);
    };

    const clearDate = () => {
        setExpirationDate(null);
    };

    const renderMessageContent = (textValue) => {
        let text = textValue.replace(/<br>!/g, '');
        let cleanText = text.replace(/(YYY|YXY)([\s\S]*?)XXX/g, (match, p1, p2) => {
            return p1 + p2.replace(/\n|<br\s*\/?>/g, ' ') + 'XXX';
        });
        cleanText = cleanText.replace(/<br\s*\/?>\s*(?=XXX)/g, '');
        cleanText = cleanText.replace(/YYY\s*(\n|<br\s*\/?>)+/g, 'YYY');

        const isImageUrl = (url) => /\.(jpeg|jpg|JPG|gif|png|webp|bmp|tiff?)$/.test(url);

        const processLinksAndFormatting = (content, isBoxContent) => {
            content = content.replace(/(https?:\/\/[^\s<]+)\s*\n\s*(\S+)/g, (m, p1, p2) => p1 + p2);
            content = content.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (m, linkText, linkUrl) => {
                const encodedUrl = encodeURI(linkUrl);
                if (isImageUrl(encodedUrl)) {
                    return `<img src="${encodedUrl}" alt="${linkText}" style="max-width: 100%; height: auto; margin: 10px 0; display: block;" />`;
                }
                return isBoxContent
                    ? `<span class="product-link" data-url="${encodedUrl}">${linkText}</span>`
                    : `<a href="${encodedUrl}" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
            });
            content = content.replace(/\*\*(.*?)\*\*/g, '$1');
            content = content.replace(
                /(https?:\/\/[^\s<]+\.(jpeg|jpg|JPG|gif|png|webp|bmp|tiff?)(\?[^<>\s]*)?)/gi,
                (matchVal) => {
                    const encodedUrl = encodeURI(matchVal);
                    return `<img src="${encodedUrl}" alt="Product Image" style="max-width: 100%; height: auto; margin: 10px 0; display: block;" />`;
                }
            );
            return content;
        };

        const productBlockPattern = /XXX([\s\S]*?)YYY/g;
        const productSeparatorPattern = /YXY/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = productBlockPattern.exec(cleanText)) !== null) {
            if (match.index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: processLinksAndFormatting(
                        cleanText.substring(lastIndex, match.index),
                        false
                    ),
                });
            }
            const productSections = match[1].split(productSeparatorPattern).map((p) => p.trim());
            if (productSections.length > 0) {
                parts.push({
                    type: 'product',
                    content: productSections,
                });
            }
            lastIndex = productBlockPattern.lastIndex;
        }

        if (lastIndex < cleanText.length) {
            parts.push({
                type: 'text',
                content: processLinksAndFormatting(cleanText.substring(lastIndex), false),
            });
        }
        return parts;
    };

    const renderProductCarousel = (productSections) => {
        const renderProductBox = (product, index) => {
            const linkPattern = /https?:\/\/[^\s<]+/g;
            const linkMatches = product.match(linkPattern) || [];
            const productLink =
                linkMatches.length > 0
                    ? linkMatches[linkMatches.length - 1].replace(/['">]/g, '')
                    : '#';

            const cleanedProductContent = product
                .replace(linkPattern, (url) => (url === productLink ? '' : url))
                .replace(
                    /(https?:\/\/[^\s<]+\.(jpeg|jpg|JPG|gif|png|webp|bmp|tiff?)(\?[^<>\s]*)?)/gi,
                    '<img src="$1" style="max-width: 100%; height: auto; margin: 10px 0; display: block;" />'
                );

            return (
                <div key={index} className={`p-0 max-w-[95%] mx-auto border ${selectedConversation?.conversation_data?.isUser ? "bg-[#686BF1] text-white" : "bg-white"} border-gray-300 mb-5 rounded-2xl text-center flex flex-col justify-between w-full h-auto`}>
                    <div className="max-w-full h-auto my-2 block mx-auto">{parse(cleanedProductContent)}</div>
                    <a
                        href={productLink}
                        className={`inline-block bg-[#36a3d9] ${selectedConversation?.conversation_data?.isUser ? " text-[#212023] bg-gradient-to-b from-white to-[#EDEEF9]" : "bg-gradient-to-b from-[#777BFF] to-[#686BF1] text-white"} shadow-[0px_4px_8px_0px_#686BF152] text-sm text-white px-5 py-2.5 no-underline rounded-2xl m-2.5`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        SE PRODUKT
                    </a>
                </div>
            );
        };

        if (productSections.length === 1) {
            return <div>{renderProductBox(productSections[0], 0)}</div>;
        }
        return (
            <Carousel responsive={responsive}>
                {productSections.map((p, idx) => renderProductBox(p, idx))}
            </Carousel>
        );
    };

    const [activeTab, setActiveTab] = useState(t('myUploadedInfo'));
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (activeTab === t('myUploadedInfo')) {
            setView('uploadedData');
            setShowConversationDetails(false);
        } else if (activeTab === t('lackingInfoConvos')) {
            setView('lackingInfoConversations');
            setSelectedConversation(null);
            setShowConversationDetails(false);
        }
    }, [activeTab]);

    const [showDatePicker, setShowDatePicker] = useState(false);

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleBackConversation = () => {
        setShowConversationDetails(false);
        setSelectedConversation(null);
    };

    const closeModal = () => {
        if (!isModalOpen.current) {
            isModalOpen.current = true;
            setShowEditModal(false);
            setShowUploadModal(false);
            setIsEditing(false);

            // Reset state values when closing the modal
            setTitle('');
            setText('');
            setExpirationDate(null);
            setSelectedIndexes([]);

            setTimeout(() => {
                isModalOpen.current = false;
            }, 100);
        }
    };
    const handleSubmitModal = () => {
        handleUpload();
        closeModal();
    };

    const openUploadModal = () => {
        setShowUploadModal(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datePickerRef]);

    return (
        <div className="h-full flex w-full lg:rounded-3xl">
            {/* LEFT PANEL */}
            <div className={`min-w-[280px] rounded-3xl overflow-y-auto bg-[#F9FBFC] h-full flex mt-10 lg:mt-0 overflow-auto flex-col gap-y-4 px-4 py-6 w-full ${showConversationDetails ? 'hidden lg:block lg:w-[300px]' : 'lg:w-[300px]'} `}>
                {/* <h3 className="lg:block hidden text-[#212023] leading-0 mb-0 text-2xl font-medium">
                    {view === 'uploadedData' ? t('myUploadedInfo') : t('lackingInfoConvos')}
                </h3> */}
                <div className={` ${showConversationDetails ? "mb-4" : "my-0"}`}>
                    <TabGroup
                        tabsArray={[t('myUploadedInfo'), t('lackingInfoConvos')]}
                        onTabChange={handleTabChange}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        tabWidth='max-w-[280px]'
                    />
                </div>
                <div className={`text-[#212023] lg:hidden flex justify-between items-center text-base font-medium ${showConversationDetails ? "my-4" : "my-0"}`}>
                    <span>
                        {view === 'uploadedData' ? t('myUploadedInfo') : t('lackingInfoConvos')}
                    </span>
                    {view === 'lackingInfoConversations' && (
                        <button
                            className='px-3 py-2 bg-[#686BF1] rounded-lg text-white text-sm font-medium'
                            onClick={openUploadModal} // Open the upload modal
                        >
                            Upload Info
                        </button>
                    )}
                </div>
                {view === 'uploadedData' ? (
                    <>
                        {
                            isLoading ? (<Skeleton active />) : (
                                <div className="flex flex-col gap-y-2">
                                    {dataList.map((item, index) => (
                                        <div
                                            key={index}
                                            className={`rounded-3xl p-3 flex flex-col border border-[#DBDCEC] gap-y-2 ${selectedItem === item ? 'bg-[#EEEFFA]' : ''}`}
                                            onClick={() => toggleExpand(index)}
                                        >
                                            <div className="flex justify-between items-center">
                                                <Tooltip title={item.title} placement="topLeft">
                                                    <span
                                                        className="text-sm inline-block max-w-[calc(100%-3rem)] mr-[0.5rem] font-medium text-[#212023] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {item.title}
                                                    </span>
                                                </Tooltip>

                                                <img
                                                    src="/svgs/edit.svg"
                                                    alt="Edit"
                                                    className="w-4 h-4 cursor-pointer"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEdit(item.entries, item.title, item.text);
                                                    }}
                                                />
                                            </div>
                                            {expandedItems[index] && (
                                                <div className="uploadData-itemContent">
                                                    <div className="text-xs font-normal text-[#212023] border-t border-[#DBDCEC] mt-2 pt-2">
                                                        <span className="break-all opacity-60"> {item.text}</span>
                                                    </div>
                                                    <div className='w-full border-t border-[#DBDCEC] mt-2 pt-2'>
                                                        {item.entries.map((entry, index) => (
                                                            <div className={`${index !== item.entries.length - 1 && "border-b border-[#DBDCEC] mb-2 pb-2"}`} key={entry.id}>
                                                                <div key={entry.id} className="flex justify-between w-full">
                                                                    <span className="text-xs font-light text-[#686BF1]">
                                                                        {entry.pinecone_index_name}
                                                                    </span>
                                                                    <img
                                                                        src="/svgs/delete.svg"
                                                                        alt="Delete"
                                                                        className="w-4 h-4 cursor-pointer"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleDelete(entry.id, entry.pinecone_index_name);
                                                                        }}
                                                                    />
                                                                </div>
                                                                {entry.expiration_time && (
                                                                    <div className='text-xs font-light flex justify-between text-[#3b2f2f] mt-2'>
                                                                        <div> {t('expiresAt')}{':'}</div>
                                                                        <div>
                                                                            {format(new Date(entry.expiration_time), 'yyyy-MM-dd HH:mm')}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </>
                ) : (
                    <>
                        {
                            isLoading ? (<Skeleton active />) : (
                                <div className='h-[calc(100vh-160px)] flex flex-col gap-y-2 overflow-y-auto pr-3'>
                                    {conversations.map((conversation) => (
                                        <ConversationCard
                                            key={conversation.id}
                                            conversation={conversation}
                                            handleConversationClick={() => handleConversationClick(conversation)}
                                            t={t}
                                            isActive={selectedConversation?.id === conversation.id}
                                        />
                                    ))}
                                </div>
                            )
                        }
                    </>
                )}
            </div>
            {view === 'lackingInfoConversations' && showConversationDetails && (
                <div className="flex-grow h-full flex flex-col w-full mt-10 px-6 bg-[#EEEFFA] rounded-l-3xl lg:hidden">
                    <h2 className="text-2xl flex items-center justify-start lg:gap-0 gap-2 font-semibold text-[#686BF1] mb-0 p-6">
                        <img src='/svgs/left-arrow.svg' onClick={handleBackConversation} alt='...' className='block cursor-pointer lg:hidden w-6 h-6' />
                        {selectedConversation ? t('conversationDetails') : t('uploadDataHeader')}
                    </h2>

                    {selectedConversation ? (
                        <div className="overflow-y-auto w-full px-2">
                            {selectedConversation.conversation_data
                                .filter((msg) => msg.text && msg.text.trim() !== '')
                                .map((msg, i) => {
                                    const parts = renderMessageContent(msg.text);
                                    return parts.map((part, j) => {
                                        if (part.type === 'text') {
                                            return (
                                                <div
                                                    key={`${i}-${j}`}
                                                    className={`w-full m-4 text-sm font-normal p-4 max-w-[250px] ${msg.isUser
                                                        ? 'ml-auto font-light text-white text-xs bg-[#686BF1] rounded-l-2xl rounded-tr-2xl shadow-[0px_2px_6px_0px_#2B2B3214]'
                                                        : 'text-[#2B2B32] font-light text-xs bg-white rounded-r-2xl rounded-tl-2xl shadow-[0px_2px_6px_0px_#2B2B3214]'
                                                        }`}
                                                >
                                                    <p className="break-words mb-0 text-sm leading-relaxed font-light">{parse(part.content)}</p>
                                                </div>
                                            );
                                        } else if (part.type === 'product') {
                                            return (
                                                <div
                                                    key={`${i}-${j}`}
                                                    className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-5 w-full"
                                                >
                                                    {renderProductCarousel(part.content)}
                                                </div>
                                            );
                                        }
                                        return null;
                                    });
                                })}
                        </div>
                    ) : (
                        <div className=" w-full p-6">
                            <p>{t('noConversationSelected')}</p>
                        </div>
                    )}
                </div>
            )}
            {view === 'lackingInfoConversations' &&
                <div className={`flex-grow h-full flex-col w-full bg-[#EEEFFA] rounded-l-3xl pr-3 hidden lg:flex ${showConversationDetails ? 'hidden' : 'flex'}`}>
                    <h2 className="text-2xl font-semibold text-[#686BF1] mb-0 p-6">
                        {selectedConversation ? t('conversationDetails') : t('uploadDataHeader')}
                    </h2>
                    {selectedConversation ? (
                        <div className="overflow-y-auto w-full px-2">
                            {selectedConversation.conversation_data
                                .filter((msg) => msg.text && msg.text.trim() !== '')
                                .map((msg, i) => {
                                    const parts = renderMessageContent(msg.text);
                                    return parts.map((part, j) => {
                                        if (part.type === 'text') {
                                            return (
                                                <div
                                                    key={`${i}-${j}`}
                                                    className={`m-4 text-sm font-normal p-4 max-w-[528px] w-fit ${msg.isUser
                                                        ? 'ml-auto text-white bg-[#686BF1] rounded-l-2xl rounded-tr-2xl shadow-[0px_2px_6px_0px_#2B2B3214]'
                                                        : 'text-[#2B2B32] bg-white rounded-r-2xl rounded-tl-2xl shadow-[0px_2px_6px_0px_#2B2B3214]'}
                        `}
                                                >
                                                    <p className="break-words mb-0 text-sm leading-relaxed font-light">
                                                        {parse(part.content)}
                                                    </p>
                                                </div>
                                            );
                                        } else if (part.type === 'product') {
                                            return (
                                                <div
                                                    key={`${i}-${j}`}
                                                    className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-5 w-full"
                                                >
                                                    {renderProductCarousel(part.content)}
                                                </div>
                                            );
                                        }
                                        return null;
                                    });
                                })}
                        </div>
                    ) : (
                        <div className=" w-full p-6">
                            <p>{t('noConversationSelected')}</p>
                        </div>
                    )}
                </div>
            }

            {/* MODAL FOR EDITING UPLOADED DATA */}
            <Modal show={showEditModal} onClose={closeModal}>
                <div className="flex flex-col gap-y-4">
                    <div className='flex justify-between pb-3 items-center'>
                        <span className='text-sm font-bold text-[#686BF1]'>Upload and Manage Information</span>
                        <span className='text-xl font-semibold cursor-pointer' onClick={closeModal}>x</span>
                    </div>
                    <InputField
                        type='text'
                        label='Information Title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={t('requiredTitlePlaceholder')}
                        maxLength={255}
                        id="edit-modal-title"
                        onFocus={() => handleFocus("edit-modal-title")}
                        isFocused={isFieldFocused("edit-modal-title")}
                    />
                    <InputField
                        type='textarea'
                        label='Information Description'
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder={t('uploadInfoPlaceholder')}
                        rows={4}
                        id="edit-modal-information"
                        onFocus={() => handleFocus("edit-modal-information")}
                        isFocused={isFieldFocused("edit-modal-information")}
                    />
                    <InputField
                        type="text"
                        label={t('expirationPicker')}
                        placeholder={translations[language]?.expirationPicker}
                        value={expirationDate ? format(expirationDate, 'yyyy-MM-dd HH:mm') : ''}
                        onClick={toggleDatePicker}
                        readOnly
                        id="edit-modal-date"
                        onFocus={() => handleFocus("edit-modal-date")}
                        isFocused={isFieldFocused("edit-modal-date")}
                    />
                    {showDatePicker && (
                        <div className="absolute z-10 bg-white rounded-md shadow-lg border border-gray-200">
                            <DatePicker
                                selected={expirationDate}
                                onChange={(date) => {
                                    setExpirationDate(date);
                                    setShowDatePicker(false);
                                }}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="yyyy-MM-dd HH:mm"
                                inline
                            />
                        </div>
                    )}
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedIndexes}
                        onChange={handleIndexSelection}
                        options={indexes.map((index) => ({
                            value: index.namespace,
                            label: index.index_name || 'Default Index',
                        }))}
                        placeholder="Select The Option"
                        styles={customStyles} // Apply custom styles
                    />
                    {message && <p className="text-lg text-[#FF0000]">{message}</p>}

                    <div className="flex flex-col items-center w-full gap-2">
                        <button
                            className={`bg-gradient-to-b from-[#777BFF] to-[#686BF1] shadow-[0px_4px_8px_0px_#686BF152] h-[48px] w-full flex justify-center items-center text-white font-bold px-4 rounded-full disabled:opacity-50`}
                            onClick={handleSubmitModal}
                            disabled={isUploading || isMarkingResolved}
                        >
                            {isUploading
                                ? isEditing
                                    ? t('updating')
                                    : t('uploading')
                                : isEditing
                                    ? t('update')
                                    : t('upload')}
                            {isUploading && <span className="spinner"></span>}
                        </button>
                        <button
                            className="bg-gradient-to-b from-[#FF7851] to-[#FF6A3F] shadow-[0px_4px_8px_0px_#FF785152] w-full h-[48px] flex justify-center items-center text-white font-bold px-4 rounded-full disabled:opacity-50"
                            onClick={closeModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showUploadModal} onClose={closeModal}>
                <div className="flex flex-col gap-y-4">
                    <div className='flex justify-between pb-3 items-center'>
                        <span className='text-sm font-bold text-[#686BF1]'>Upload Information</span>
                        <span className='text-xl font-semibold cursor-pointer' onClick={closeModal}>x</span>
                    </div>
                    <InputField
                        type='text'
                        label='Information Title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={t('requiredTitlePlaceholder')}
                        maxLength={255}
                        id="upload-modal-title"
                        onFocus={() => handleFocus("upload-modal-title")}
                        isFocused={isFieldFocused("upload-modal-title")}
                    />
                    <InputField
                        type='textarea'
                        label='Information Description'
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder={t('uploadInfoPlaceholder')}
                        rows={4}
                        id="upload-modal-information"
                        onFocus={() => handleFocus("upload-modal-information")}
                        isFocused={isFieldFocused("upload-modal-information")}
                    />
                    <InputField
                        type="text"
                        label={t('expirationPicker')}
                        placeholder={translations[language]?.expirationPicker}
                        value={expirationDate ? format(expirationDate, 'yyyy-MM-dd HH:mm') : ''}
                        onClick={toggleDatePicker}
                        readOnly
                        id="upload-modal-date"
                        onFocus={() => handleFocus("upload-modal-date")}
                        isFocused={isFieldFocused("upload-modal-date")}
                    />
                    {showDatePicker && (
                        <div className="absolute z-10 bg-white rounded-md shadow-lg border border-gray-200">
                            <DatePicker
                                selected={expirationDate}
                                onChange={(date) => {
                                    setExpirationDate(date);
                                    setShowDatePicker(false);
                                }}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="yyyy-MM-dd HH:mm"
                                inline
                            />
                        </div>
                    )}
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedIndexes}
                        onChange={handleIndexSelection}
                        options={indexes.map((index) => ({
                            value: index.namespace,
                            label: index.index_name || 'Default Index',
                        }))}
                        placeholder="Select The Option"
                        styles={customStyles} // Apply custom styles
                    />
                    <div className="flex flex-col items-center w-full gap-2">
                        <button
                            className={`bg-gradient-to-b from-[#777BFF] to-[#686BF1] shadow-[0px_4px_8px_0px_#686BF152] h-[48px] w-full flex justify-center items-center text-white font-bold px-4 rounded-full disabled:opacity-50`}
                            onClick={handleSubmitModal}
                            disabled={isUploading || isMarkingResolved}
                        >
                            {isUploading ? t('uploading') : t('upload')}
                            {isUploading && <span className="spinner"></span>}
                        </button>
                        <button
                            className="bg-gradient-to-b from-[#FF7851] to-[#FF6A3F] shadow-[0px_4px_8px_0px_#FF785152] w-full h-[48px] flex justify-center items-center text-white font-bold px-4 rounded-full disabled:opacity-50"
                            onClick={closeModal}
                        >
                            {isMarkingResolved ? t('removing') : t('remove')}
                        </button>
                    </div>
                </div>
            </Modal>
            <div className={`flex-col gap-y-4 w-full h-full px-4 py-6 ${view === 'lackingInfoConversations' ? "bg-white rounded-r-3xl max-w-[320px] lg:flex hidden" : "bg-[#EEEFFA] rounded-3xl lg:flex hidden"} overflow-y-auto `}>
                <span className={`${view === 'lackingInfoConversations' ? "text-lg" : " text-2xl"} font-semibold text-[#686BF1] pb-4`}>{t('uploadDataHeader')}</span>
                <InputField
                    type='text'
                    label={t('requiredTitlePlaceholder')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={t('requiredTitlePlaceholder')}
                    maxLength={255}
                    backgroundColor={`${view === 'lackingInfoConversations' ? "bg-white" : "bg-[#EEEFFA]"}`}
                    id="main-title"
                    onFocus={() => handleFocus("main-title")}
                    isFocused={isFieldFocused("main-title")}
                />
                <InputField
                    type='textarea'
                    label={t('uploadInfoPlaceholder')}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={t('uploadInfoPlaceholder')}
                    rows={4}
                    backgroundColor={`${view === 'lackingInfoConversations' ? "bg-white" : "bg-[#EEEFFA]"}`}
                    id="main-information"
                    onFocus={() => handleFocus("main-information")}
                    isFocused={isFieldFocused("main-information")}
                />
                <div className={` ${view === "lackingInfoConversations" ? "flex flex-col gap-y-3" : "grid grid-cols-1 gap-3"}`}>
                    <div>
                    <InputField
                            type="text"
                            label={t('expirationPicker')}
                            placeholder={translations[language]?.expirationPicker}
                            value={expirationDate ? format(expirationDate, 'yyyy-MM-dd HH:mm') : ''}
                            onClick={toggleDatePicker}
                            readOnly
                            backgroundColor={`${view === 'lackingInfoConversations' ? "bg-white" : "bg-[#EEEFFA]"}`}
                            id="main-date"
                            onFocus={() => handleFocus("main-date")}
                            isFocused={isFieldFocused("main-date")}
                        />
                        {expirationDate && (
                            <button
                                type="button"
                                className="absolute top-72 right-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                                onClick={clearDate}
                            >
                                <span className="bg-gray-700 flex justify-center items-center rounded-full text-white h-5 w-5">x</span>
                            </button>
                        )}
                        {showDatePicker && (
                            <div className={`absolute z-10 ${view === 'lackingInfoConversations' ? "right-8" : ""} bg-white rounded-md shadow-lg border border-gray-200`} ref={datePickerRef}>
                                <DatePicker
                                    selected={expirationDate}
                                    onChange={(date) => {
                                        setExpirationDate(date);
                                        setShowDatePicker(false);
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    inline
                                />
                            </div>
                        )}
                    </div>
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedIndexes}
                        onChange={handleIndexSelection}
                        options={indexes.map((index) => ({
                            value: index.namespace,
                            label: index.index_name || 'Default Index',
                        }))}
                        placeholder="Select The Option"
                        styles={customStyles} // Apply custom styles
                    />
                </div>

                {message && <p className="text-lg text-[#FF0000]">{message}</p>}

                <div className="flex flex-col items-center w-full gap-2">
                    <button
                        className={`bg-gradient-to-b from-[#777BFF] to-[#686BF1] shadow-[0px_4px_8px_0px_#686BF152] h-[48px] ${view === "lackingInfoConversations" ? "w-full" : "w-[159px]"} flex justify-center items-center text-white font-medium px-4 rounded-full disabled:opacity-50`}
                        onClick={handleUpload}
                        disabled={isUploading || isMarkingResolved}
                    >
                        {isUploading
                            ? isEditing
                                ? t('updating')
                                : t('uploading')
                            : isEditing
                                ? t('update')
                                : t('upload')}
                        {isUploading && <span className="spinner"></span>}
                    </button>

                    {isEditing && (
                        <button
                            className="bg-gradient-to-b from-[#FF7851] to-[#FF6A3F] shadow-[FF785152] w-[159px] h-[48px] flex justify-center items-center text-white font-medium px-4 rounded-full disabled:opacity-50"
                            onClick={() => {
                                setIsEditing(false);
                                setEditingEntries([]);
                                setTitle('');
                                setText('');
                                setExpirationDate(null);
                                setSelectedIndexes([]);
                            }}
                        >
                            {t('cancel')}
                        </button>
                    )}

                    {view === 'lackingInfoConversations' && (
                        <button
                            onClick={markConversationResolved}
                            className="bg-gradient-to-b from-[#FF7851] to-[#FF6A3F] w-full shadow-[0px_4px_8px_0px_#FF785152] h-[48px] flex justify-center items-center text-white font-medium px-4 rounded-full disabled:opacity-50"
                            disabled={isMarkingResolved || isUploading}
                        >
                            {isMarkingResolved ? t('removing') : t('remove')}
                            {isMarkingResolved && <span className="spinner"></span>}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UploadData;