import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputField from '../../components/InputField';

function Users() {
  const [users, setUsers] = useState([]);

  // Basic user fields
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');

  // Additional register fields
  const [chatbotIds, setChatbotIds] = useState('');
  const [pineconeApiKey, setPineconeApiKey] = useState('');
  const [pineconeIndexes, setPineconeIndexes] = useState('');
  const [showPurchase, setShowPurchase] = useState(false);
  const [chatbotFilepath, setChatbotFilepath] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  // UI feedback
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // Mock isMobile and showMobileChat for demonstration purposes
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024); // Detect mobile screen size
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 1024;
      setIsMobile(newIsMobile);
      setIsSidebarOpen(!newIsMobile); // Adjust sidebar state based on screen size
    };

    window.addEventListener('resize', handleResize);

    // Initial setup on mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // On mount, fetch all users
  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch all users
  const fetchUsers = async () => {
    setError('');
    setMessage('');
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.get(
        'https://egendatabasebackend.onrender.com/users',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUsers(response.data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Error fetching users.');
    }
  };

  // Helper to parse strings into arrays
  const parseChatbotIds = (str) => {
    if (!str.trim()) return [];
    return str.split(',').map((s) => s.trim());
  };

  const parsePineconeIndexes = (str) => {
    if (!str.trim()) return [];
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  };

  // Submit new user
  const handleCreateUser = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    // Build request body
    const body = {
      username: newUsername,
      password: newPassword,
      chatbot_ids: parseChatbotIds(chatbotIds),
      pinecone_api_key: pineconeApiKey,
      pinecone_indexes: parsePineconeIndexes(pineconeIndexes),
      show_purchase: showPurchase,
      chatbot_filepath: chatbotFilepath,
      is_admin: isAdmin,
    };

    try {
      await axios.post(
        'https://egendatabasebackend.onrender.com/register',
        body
      );
      setMessage('User created successfully!');
      // Clear form
      setNewUsername('');
      setNewPassword('');
      setChatbotIds('');
      setPineconeApiKey('');
      setPineconeIndexes('');
      setShowPurchase(false);
      setChatbotFilepath('');
      setIsAdmin(false);
      // Refresh user list
      fetchUsers();
    } catch (err) {
      console.error('Error creating user:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('Error creating new user.');
      }
    }
  };

  const t = (key) => { // Mock translation function
    switch (key) {
      case 'sidebarTitle': return 'User Management';
      case 'noStatus': return 'No Status';
      case 'reportBug': return 'Report Bug';
      case 'markResolved': return 'Mark Resolved';
      case 'removeStatus': return 'Remove Status';
      default: return key;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  return (
    <div className="h-full flex w-full lg:mt-0 mt-10 lg:rounded-3xl">
      {isMobile && (
        <div className='flex justify-end items-center w-full mt-10 absolute top-5 left-0 px-5'>
          <button
            className="w-8 h-8 flex justify-center items-center cursor-pointer rounded-full bg-white"
            onClick={toggleSidebar}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
      )}

      <div
        className={`lg:min-w-[300px] lg:rounded-3xl flex flex-col gap-y-4 lg:p-5 overflow-auto bg-white ${isSidebarOpen
          ? 'fixed top-0 left-0 w-[309px] h-full z-40 transform translate-x-0 transition-transform duration-300 ease-in-out p-5'
          : 'transform -translate-x-full lg:translate-x-0 transition-transform duration-300 ease-in-out'
          } ${!isSidebarOpen ? 'w-0' : ''} lg:block lg:relative lg:w-auto`}
        style={{
          zIndex: isSidebarOpen ? 50 : 'auto',
        }}
      >
        <div className='flex justify-between'>
          <h2 className="text-[#212023] leading-0 mb-2 text-2xl font-medium">{t('sidebarTitle')}</h2>
          <span className='lg:hidden block text-base font-semibold cursor-pointer' onClick={() => setIsSidebarOpen(false)}> x </span>
        </div>
        <div className='my-4'>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {message && <div className="text-green-500 mb-4">{message}</div>}

          <form onSubmit={handleCreateUser} className="flex flex-col gap-4">
            <div>
              <InputField
                label="Username:"
                type="text"
                autofillBackgroundColor={"#fff"}
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
                name="new-username"
                id="new-username"
              />
            </div>

            <div>
              <InputField
                label="Password:"
                type="password"
                autofillBackgroundColor={"#fff"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                name="new-password"
                id="new-password"
              />
            </div>

            <div>
              <InputField
                label="chatbot_ids (comma-separated):"
                type="text"
                value={chatbotIds}
                onChange={(e) => setChatbotIds(e.target.value)}
                required
                name="chatbot_ids"
                id="chatbot_ids"
              />
            </div>

            <div>
              <InputField
                label="pinecone_api_key:"
                type="text"
                value={pineconeApiKey}
                onChange={(e) => setPineconeApiKey(e.target.value)}
                required
                name="pinecone_api_key"
                id="pinecone_api_key"
              />
            </div>

            <div>
              <InputField
                label="pinecone_indexes (JSON array):"
                type="text"
                value={pineconeIndexes}
                onChange={(e) => setPineconeIndexes(e.target.value)}
                required
                name="pinecone_indexes"
                id="pinecone_indexes"
              />
            </div>

            <div className='flex items-center gap-4'>
              <label className="block text-sm font-medium text-gray-700">show_purchase:</label>
              <input
                type="checkbox"
                checked={showPurchase}
                onChange={(e) => setShowPurchase(e.target.checked)}
                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
            </div>

            <div>
              <InputField
                label="chatbot_filepath:"
                type="text"
                value={chatbotFilepath}
                onChange={(e) => setChatbotFilepath(e.target.value)}
                required
                name="chatbot_filepath"
                id="chatbot_filepath"
              />
            </div>

            <div className='flex items-center gap-4'>
              <label className="block text-sm font-medium text-gray-700">is_admin:</label>
              <input
                type="checkbox"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
            </div>

            <button type="submit" className="w-full h-[48px] text-white text-sm font-normal flex justify-center items-center rounded-full bg-gradient-to-b from-[#777BFF] to-[#686BF1] shadow-[0px_4px_8px_0px_#686BF152]">
              Create User
            </button>
          </form>
        </div>


      </div>

      <div className="flex flex-col justify-start items-start px-5 overflow-y-auto w-full bg-[#EEEFFA] rounded-3xl lg:w-[calc(100vw-600px)] lg:flex-grow mt-0 lg:mt-0"> {/* Removed mt-24 */}
        <div className="pt-6">
          <h2 className="text-[#686BF1] flex items-center lg:gap-0 gap-2 text-2xl font-medium">User List</h2>
        </div>

        <div className="overflow-auto mb-5 w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-[#E5E6F3]">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Admin?</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map((u) => (
                <tr key={u.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{u.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{u.username}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{u.is_admin ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Users;