import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputField from '../../components/InputField';

function Login({ setIsAuthenticated, setChatbotIds }) { // Accept chatbotIds & setChatbotIds
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    let isValid = true;
    const usernameRegex = /^[a-zA-Z0-9_.@]+$/; // Allows alphanumeric, underscores, periods, and @
    if (!email) {
      setError('Username is required');
      isValid = false;
      return
    } else if (!usernameRegex.test(email)) {
      setError('Username can only contain letters, numbers, underscores (_), periods (.), and @');
      isValid = false;
      return
    } else if (email.length < 3 || email.length > 50) {
      setError('Username must be between 3 and 50 characters');
      isValid = false;
      return
    }

    // Password Validation
    if (!password) {
      setError('Password is required');
      isValid = false;
      return
    } else if (password.length < 6) {
      setError('Password must be at least 6 characters');
      isValid = false;
      return
    }

    if (!isValid) {
      return;
    }

    // Activate loading
    setLoading(true);

    try {
      const response = await axios.post('https://egendatabasebackend.onrender.com/login', {
        username: email.toLowerCase(), // Convert username to lowercase
        password,
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);

        if (response.data.chatbot_ids && response.data.chatbot_ids.length > 0) {
          localStorage.setItem('chatbot_ids', JSON.stringify(response.data.chatbot_ids));
          setChatbotIds(response.data.chatbot_ids); // update parent state
        } else {
          localStorage.setItem('chatbot_ids', JSON.stringify([]));
          setChatbotIds([]); // update parent state
        }

        localStorage.setItem('show_purchase', response.data.show_purchase);

        // NEW: Store the chatbot file path
        localStorage.setItem('chatbot_filepath', response.data.chatbot_filepath || '');

        localStorage.setItem('is_admin', response.data.is_admin ? 'true' : 'false');

        setIsAuthenticated(true);
        setLoading(false);
        navigate('/');
      } else {
        setError('Invalid email or password');
        setLoading(false); // Stop loading on error
      }
    } catch (error) {
      console.error('Error logging in:', error);

      // Handle error from the server response
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message); // Show backend error message if available
      } else {
        setError('An error occurred. Please try again.');
      }

      setLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="min-h-screen w-full bg-[#f8f8fc] flex justify-center items-center p-5">
      <div className="bg-white rounded-[32px] max-w-[500px] w-full px-6 py-7 shadow-lg">
        <img
          src="/pngs/logo.png"
          alt="..."
          className="w-[172px] h-auto"
        />
        <div className="mt-2">
          <h2 className="text-2xl pb-3 text-[#212023] font-bold">Log In Your Account</h2>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 mb-5">
          <InputField
            name="username"
            id="username"
            label="Username"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your username"
            required={false}
            autofillBackgroundColor={"#fff"}
            disabled={loading}
            suffix={<img src="/svgs/bx_user.svg" alt="user" className="h-4 w-4" />}
          />

          <InputField
            name="password"
            id="password"
            label="Password"
            type="password"
            placeholder="Enter Your Password"
            required={false}
            autofillBackgroundColor={"#fff"}
            value={password}
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

          <button
            className="w-full shadow-[0px_4px_8px_0px_#686BF152] text-[#FFFFFF] text-lg font-medium bg-gradient-to-t from-[#777BFF] rounded-full to-[#686BF1] h-[56px] flex justify-center items-center"

            type="submit" disabled={loading}>
            {loading ? 'Logger ind...' : 'Login'} {/* Show loading text */}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
