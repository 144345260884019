import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { translationStatistics } from '../../utils/translations';
import StatsCard from '../../components/StatsCard';
import { Skeleton } from 'antd';
import InputField from '../../components/InputField';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const translations = translationStatistics;

function Statistics({ isSidebarOpen }) {
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('appLanguage') || 'da';
  });

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'appLanguage' && e.newValue !== null && e.newValue !== language) {
        setLanguage(e.newValue || 'da');
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [language]);

  const t = (key) => translations[language]?.[key] || key;

  const [dailyData, setDailyData] = useState(null);
  const [hourlyData, setHourlyData] = useState(null);
  const [emneData, setEmneData] = useState(null);

  const [totalMessages, setTotalMessages] = useState(0);
  const [averageMessagesPerDay, setAverageMessagesPerDay] = useState(0);
  const [totalConversations, setTotalConversations] = useState(0);
  const [totalCustomerRatings, setTotalCustomerRatings] = useState(0);
  const [averageCustomerRating, setAverageCustomerRating] = useState('N/A');

  const [loading, setLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState('all');
  const [startDate, setStartDate] = useState(null);  // Changed to null to hold Date objects
  const [endDate, setEndDate] = useState(null);      // Changed to null to hold Date objects
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const API_URL = 'https://egendatabasebackend.onrender.com';

  useEffect(() => {
    const getStatistics = async () => {
      setLoading(true);

      // Must have token
      const token = localStorage.getItem('token');
      if (!token) {
        console.error(t('tokenMissing'));
        setLoading(false);
        return;
      }

      // Retrieve user's entire chatbot_ids
      const storedChatbotIds = localStorage.getItem('chatbot_ids');
      const chatbotIds = storedChatbotIds ? JSON.parse(storedChatbotIds) : [];

      // Retrieve the user-chosen ID from localStorage (or "ALL")
      const chosenId = localStorage.getItem('selected_chatbot_id') || 'ALL';

      if (chatbotIds.length === 0) {
        console.error(t('tokenMissing'));
        setLoading(false);
        return;
      }

      // Build a comma-separated list or single ID
      let joinedIds = '';
      if (chosenId === 'ALL') {
        joinedIds = chatbotIds.join(',');
      } else {
        joinedIds = chosenId; // Single ID
      }

      try {
        // Compute start/end date
        let start = startDate ? startDate.toISOString() : null;
        let end = endDate ? endDate.toISOString() : new Date().toISOString();

        if (timePeriod === '7') {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          start = date.toISOString();
        } else if (timePeriod === '30') {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          start = date.toISOString();
        } else if (timePeriod === 'yesterday') {
          // Calculate yesterday's start and end times
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          const startOfYesterday = yesterday.toISOString();
          const endOfYesterday = new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate(),
            23,
            59,
            59,
            999
          ).toISOString();
          start = startOfYesterday;
          end = endOfYesterday;
        }

        // Query params
        const params = { chatbot_id: joinedIds };
        if (start) {
          params.start_date = start;
          params.end_date = end;
        }

        const response = await axios.get(`${API_URL}/conversations`, {
          headers: { Authorization: `Bearer ${token}` },
          params,
        });

        if (!Array.isArray(response.data)) {
          console.error('Invalid data structure:', response.data);
          setLoading(false);
          return;
        }

        // Sort newest to oldest
        const sortedConversations = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        // Setup counters
        const dailyMessages = {};
        const hourlyMessages = Array(24).fill(0);
        const emneCounts = {};

        let totalMessagesCount = 0;
        let totalCustomerRating = 0;
        let customerRatingCount = 0;

        for (const convo of sortedConversations) {
          const timestamp = convo.created_at;
          if (!timestamp) continue;

          const dateObj = new Date(timestamp);
          if (isNaN(dateObj.getTime())) continue;

          // day / hour keys
          const dayKey = `${dateObj.getFullYear()}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${('0' + dateObj.getDate()).slice(-2)}`;

          const hour = dateObj.getHours();

          // some logic for messages
          const convoData = convo.conversation_data || [];
          const halfMessages = (convoData.length - 1) / 2;

          if (!dailyMessages[dayKey]) dailyMessages[dayKey] = 0;
          dailyMessages[dayKey] += halfMessages;

          hourlyMessages[hour] += halfMessages;
          totalMessagesCount += halfMessages;

          // topic / emne
          const emne = convo.emne || null;
          if (emne) {
            if (!emneCounts[emne]) emneCounts[emne] = 0;
            emneCounts[emne] += 1;
          }

          // rating
          const userRating = convo.customer_rating;
          if (userRating !== null && userRating !== undefined) {
            totalCustomerRating += userRating;
            customerRatingCount += 1;
          }
        }

        // Averages
        const avgRating =
          customerRatingCount > 0
            ? (totalCustomerRating / customerRatingCount).toFixed(2)
            : 'N/A';

        setTotalMessages(Math.round(totalMessagesCount));
        setAverageCustomerRating(avgRating);
        setTotalConversations(sortedConversations.length);
        setTotalCustomerRatings(customerRatingCount);

        const uniqueDays = Object.keys(dailyMessages).length;
        if (uniqueDays > 0) {
          setAverageMessagesPerDay((totalMessagesCount / uniqueDays).toFixed(2));
        } else {
          setAverageMessagesPerDay(0);
        }

        // Chart data
        setDailyData({
          labels: Object.keys(dailyMessages),
          datasets: [
            {
              label: t('dailyMessages'),
              data: Object.values(dailyMessages).map((val) => Math.round(val)),
              fill: false,
              backgroundColor: '#1abc9c',
              borderColor: '#16a085',
              borderWidth: 2,
            },
          ],
        });

        setHourlyData({
          labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
          datasets: [
            {
              label: t('timeOfDay'),
              data: hourlyMessages,
              backgroundColor: '#3498db',
              borderColor: '#2980b9',
              borderWidth: 2,
            },
          ],
        });

        setEmneData({
          labels: Object.keys(emneCounts),
          datasets: [
            {
              label: t('topicMessages'),
              data: Object.values(emneCounts),
              backgroundColor: '#9b59b6',
              borderColor: '#8e44ad',
              borderWidth: 2,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    getStatistics();
    // window.dispatchEvent(new Event('resize'));
  }, [timePeriod, startDate, endDate]);

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
    if (e.target.value === 'custom') {
      setOpenStartDatePicker(true);
    } else {
      setOpenStartDatePicker(false);
      setOpenEndDatePicker(false);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setOpenStartDatePicker(false); // Optionally close after selection
  };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setOpenEndDatePicker(false); // Optionally close after selection
    };

  const statItems = [
    { image: "/svgs/message-new.svg", value: totalMessages, label: t("totalMessages") },
    { image: "/svgs/chat.svg", value: averageMessagesPerDay, label: t("avgMessagesPerDay") },
    { image: "/svgs/send.svg", value: totalConversations, label: t("totalConversations") },
    { image: "/svgs/star.svg", value: totalCustomerRatings, label: t("totalUserRatings") },
    { image: "/svgs/like.svg", value: averageCustomerRating, label: t("averageRating") },
  ];

  return (
    <div className={`h-full flex w-full ${isSidebarOpen ? "lg:w-[calc(100vw-250px)]" : '"lg:w-[calc(100vw-105px)]"'} lg:mt-0 mt-10 lg:rounded-3xl overflow-auto`}>
      <div className='flex flex-col px-6 w-full'>
        <div className="flex justify-between items-center py-6 flex-wrap">
          <h3 className='text-[#686BF1] mb-0 text-base lg:text-2xl font-medium'>Statistics</h3>
          <div className='flex gap-2 items-center flex-wrap'>
            <label htmlFor="timePeriod">
              <span className="text-[#212023] hidden lg:block text-sm font-normal">{t('choosePeriod')}</span>
            </label>
            <select
              className='bg-transparent lg:border lg:border-[#D3D4E5] w-auto  rounded-lg h-[48px] flex justify-center items-center px-2 outline-none'
              id="timePeriod" value={timePeriod} onChange={handleTimePeriodChange}>
              <option value="all">{t('allTimes')}</option>
              <option value="7">{t('last7Days')}</option>
              <option value="30">{t('last30Days')}</option>
              <option value="yesterday">{t('yesterday')}</option>
              <option value="custom">{t('customRange')}</option>
            </select>

            {timePeriod === 'custom' && (
              <div className="date-range flex gap-3 flex-wrap">
                <InputField
                  label="Start Date"
                  name="startDate"
                  backgroundColor='bg-[#F9FBFC]'
                  isDatePicker={true}
                  selectedDate={startDate}
                  onDateChange={handleStartDateChange}
                  openDatePicker={openStartDatePicker}
                  setOpenDatePicker={setOpenStartDatePicker}
                />
                <InputField
                  label="End Date"
                  name="endDate"
                  backgroundColor='bg-[#F9FBFC]'
                  isDatePicker={true}
                  selectedDate={endDate}
                  onDateChange={handleEndDateChange}
                  openDatePicker={openEndDatePicker}
                  setOpenDatePicker={setOpenEndDatePicker}
                />
              </div>
            )}
          </div>
        </div>

        <div className='overflow-y-auto pr-1'>

          <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-2 gap-3">
            <>
              {statItems.map((stat, index) => {
                const isLastCard = index === statItems.length - 1;
                return (
                  <div
                    key={index}
                    className={`${isLastCard ? 'lg:col-span-1 col-span-2' : ''}`}
                  >
                    {
                      loading ? (<Skeleton active />) :
                        <StatsCard
                          image={stat.image}
                          value={stat.value}
                          label={stat.label}
                          isLast={isLastCard}
                        />
                    }
                  </div>
                );
              })}
            </>
          </div>

          <div className='mt-4 mb-5 grid grid-cols-1 lg:grid-cols-2 gap-4'>
            <div>
              <h2 className='text-lg font-medium text-[#212023]'>{t('dailyMessages')}</h2>
              {
                loading ? (<Skeleton active />) : (
                  <>
                    {dailyData && (
                      <div className="min-h-[364px] bg-white rounded-[22px] py-6 px-4">
                        <Line
                          data={dailyData}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: 'top',
                                labels: { color: '#123443' },
                              },
                            },
                            scales: {
                              x: {
                                ticks: { color: '#123443' },
                                title: {
                                  display: true,
                                  text: t('dateLabel'),
                                  color: '#2980b9',
                                  font: { size: 16 },
                                },
                                grid: { color: '#7f8c8d' },
                              },
                              y: {
                                ticks: { color: '#123443' },
                                title: {
                                  display: true,
                                  // text: t('messageCount'),
                                  color: '#2980b9',
                                  font: { size: 16 },
                                },
                                grid: { color: '#7f8c8d' },
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </>)}

            </div>

            <div>
              <h2 className='text-lg font-medium text-[#212023]'>{t('timeOfDay')}</h2>
              {
                loading ? (<Skeleton active />) : (
                  <>
                    {hourlyData && (
                      <div className="min-h-[364px] bg-white rounded-[22px] py-6 px-4">
                        <Bar
                          data={hourlyData}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: 'top',
                                labels: { color: '#123443' },
                              },
                            },
                            scales: {
                              x: {
                                ticks: { color: '#123443' },
                                title: {
                                  display: true,
                                  text: t('timeLabel'),
                                  color: '#2980b9',
                                  font: { size: 16 },
                                },
                                grid: { color: '#7f8c8d' },
                              },
                              y: {
                                ticks: { color: '#123443' },
                                title: {
                                  display: true,
                                  // text: t('messageCount'),
                                  color: '#2980b9',
                                  font: { size: 16 },
                                },
                                grid: { color: '#7f8c8d' },
                              },
                            },
                          }}
                        />

                      </div>
                    )}
                  </>
                )}
            </div>
          </div>

          <div className="lg:mb-5 mb-10">
            <h2 className='text-lg font-medium text-[#212023]'>{t('topicMessages')}</h2>
            {
              loading ? (<Skeleton active />) : (
                <>
                  {emneData && (
                    <div className="min-h-[400px] bg-white rounded-[22px] py-6 px-4">
                      <Bar
                        data={emneData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'top',
                              labels: { color: '#123443' },
                            },
                          },
                          scales: {
                            x: {
                              ticks: { color: '#123443' },
                              title: {
                                display: true,
                                text: t('topicLabel'),
                                color: '#2980b9',
                                font: { size: 16 },
                              },
                              grid: { color: '#7f8c8d' },
                            },
                            y: {
                              ticks: { color: '#123443' },
                              title: {
                                display: true,
                                // text: t('conversationCount'),
                                color: '#2980b9',
                                font: { size: 16 },
                              },
                              grid: { color: '#7f8c8d' },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;