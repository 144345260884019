import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import Dashboard from "./pages/Dashboard";
import Statistics from "./pages/Statistics";
import Login from "./pages/Login";
import UploadData from "./pages/UploadData";
import CRM from "./pages/CRM";
import Chatbot from "./pages/Chatbot";
import Users from "./pages/Users";
import ProtectedRoute from "./utils/protectedRoutes";
import Sidebar from "./layouts/Sidebar";
import Navbar from "./layouts/Navbar";
import "antd/dist/reset.css";

function App() {
  const location = useLocation();
  const [chatbotIds, setChatbotIds] = useState(() => {
    const stored = localStorage.getItem('chatbot_ids');
    return stored ? JSON.parse(stored) : [];
  });

  const storedSelected = localStorage.getItem('selected_chatbot_id') || 'ALL';
  const [selectedChatbotId, setSelectedChatbotId] = useState(storedSelected)

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            handleLogout();
          } else {
            setIsAuthenticated(true);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          handleLogout();
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 25000);
    return () => clearInterval(interval);
  }, []);

  // 6. Logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('chatbot_ids');
    localStorage.removeItem('show_purchase');
    localStorage.removeItem('selected_chatbot_id');
    setIsAuthenticated(false);
    window.location.href = '/login';
  };

  // Whether the current user is admin, from local storage
  const isAdmin = localStorage.getItem('is_admin') === 'true';

  // 9. Return the JSX
  return (
    <div className={`flex items-center ${location.pathname !== '/login' ? "bg-[#212023]" : "bg-[#ffffff]"}`}>
      {/* Show navbar unless the path is /login */}
      {location.pathname !== '/login' && (
        <>
          <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} /> {/* Pass state and setter */}
          <Sidebar
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            chatbotIds={chatbotIds}
            isAdmin={isAdmin}
            selectedChatbotId={selectedChatbotId}
            setSelectedChatbotId={setSelectedChatbotId}
            isSidebarOpen={isSidebarOpen} // Pass the state
            setIsSidebarOpen={setIsSidebarOpen} // Pass the setter
          />
        </>
      )}
      <div className="lg:rounded-3xl rounded-none w-full bg-[#F9FBFC] lg:h-[calc(100vh-16px)] h-screen">
        <Routes>
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setChatbotIds={setChatbotIds} />} />
          <Route path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated} component={<Dashboard selectedChatbotId={selectedChatbotId} isSidebarOpen={isSidebarOpen} />} />} />
          <Route path="/statistics" element={<ProtectedRoute isAuthenticated={isAuthenticated} component={<Statistics isSidebarOpen={isSidebarOpen} />} />} />
          <Route path="/upload-data" element={<ProtectedRoute isAuthenticated={isAuthenticated} component={<UploadData />} />} />
          <Route path="/crm" element={<ProtectedRoute isAuthenticated={isAuthenticated} component={<CRM />} />} />
          <Route path="/chatbot" element={<ProtectedRoute isAuthenticated={isAuthenticated} component={<Chatbot />} />} />
          <Route path="/users" element={<ProtectedRoute isAuthenticated={isAuthenticated && isAdmin} component={<Users />} />} />
        </Routes>
      </div>

    </div>
  );
}

export default App;