import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const InputField = React.forwardRef(({
  type = "text",
  value = "",
  onChange,
  prefix,
  suffix,
  disabled = false,
  label = "",
  required = false,
  maxLength,
  minLength,
  rows,
  options = [],
  onClick,
  readOnly = false,
  onBlur,
  isColorPicker = false,
  isFileUpload = false,
  handleFileUpload,
  fileUploadStatus,
  fileUploadButtonText = "Choose File",
  backgroundColor = "bg-white",
  name,
  id,
  isFocused,
  onFocus,
  autofillBackgroundColor,
  isDatePicker = false,  // Add this prop to enable DatePicker
  selectedDate,            // For DatePicker value
  onDateChange,            // For DatePicker change
  openDatePicker,          // Prop to control datepicker open state
  setOpenDatePicker       // Prop to control datepicker open state

}, ref) => {
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const isTextarea = type === "textarea";
  const isSelect = type === "select";
  const inputRef = useRef(null);

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputFocus = () => {
    if (onFocus) {
      onFocus();
    }
    if (isDatePicker && setOpenDatePicker) {
      setOpenDatePicker(true); // Open DatePicker on input focus if it's a datepicker
    }
  };

  const autofillStyle = autofillBackgroundColor ? {
    WebkitBoxShadow: `0 0 0 1000px ${autofillBackgroundColor} inset`, // For Chrome/Safari
  } : {};

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  if (isColorPicker) {
    return (
      <div className="relative">
        <label
          htmlFor="colorPickerInput"
          className={`${backgroundColor} absolute font-medium text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
        <div className="relative">
          <input
            type="color"
            id="colorPickerInput"
            className={`block bg-transparent rounded-lg px-2.5 pb-2 pt-4 w-full text-sm text-black border border-gray-300 focus:outline-none peer absolute inset-0 opacity-0`}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
          <div className="flex justify-between items-center p-[0.625rem] rounded-lg bg-transparent border border-gray-300">
            <div className="flex gap-2 items-center">
              <span
                className="block w-4 h-4 rounded-sm mr-2 mt-2"
                style={{ backgroundColor: value }}
              />
              <span className="text-xs text-[#212023] font-light opacity-60 mt-2">
                {value}
              </span>
            </div>
            <img src="/svgs/fi-color.svg" alt="..." className="h-4 w-4" />
          </div>
        </div>
        {prefix && (
          <span className="absolute left-4 top-1/2 transform -translate-y-1/2">
            {prefix}
          </span>
        )}
        {suffix && (
          <span className="absolute right-4 top-1/2 transform -translate-y-1/2">
            {suffix}
          </span>
        )}
      </div>
    );
  }

  if (isFileUpload) {
    return (
      <div className="relative">
        <label
          htmlFor="file_upload"
          className={` ${backgroundColor} absolute font-medium text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          disabled={disabled}
          className="hidden"
          id="file_upload"
          ref={fileInputRef}
        />
        <button
          type="button"
          className={
            "block bg-transparent rounded-lg px-2.5 pb-2 pt-4 w-full text-sm text-black border border-gray-300 focus:outline-none peer"
          }
          onClick={handleButtonClick}
          disabled={disabled}
        >
          <span className="flex justify-center items-center gap-1">
            <img src="/svgs/my_upload.svg" alt="..." className="w-5 h-5" />
            <span className="text-xs text-[#212023] font-light opacity-60">
              {fileUploadButtonText}
            </span>
          </span>
        </button>
        {fileUploadStatus && (
          <p className="mt-2 text-sm text-gray-500">{fileUploadStatus}</p>
        )}
      </div>
    );
  }

  if (isDatePicker) {
    return (
      <div className="relative" onClick={handleContainerClick}>
        <label
          htmlFor={`floating_outlined_${id}`}
          className={`${backgroundColor} absolute font-medium text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 cursor-pointer`}
          style={{ pointerEvents: 'none' }} // Add this style
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>

        <DatePicker
          id={`floating_outlined_${id}`}
          selected={selectedDate}
          onChange={onDateChange}
          dateFormat="MM/dd/yyyy"
          open={openDatePicker} // Control DatePicker's open state
          onClickOutside={() => setOpenDatePicker(false)}
          onFocus={handleInputFocus} // Use our focus handler to open the DatePicker
          className="block bg-transparent rounded-lg px-2.5 pb-2 pt-4 w-full text-sm text-black border border-gray-300 focus:outline-none peer"  // Apply basic styling
        />
      </div>
    );
  }

  return (
    <div className="relative" onClick={handleContainerClick}>
      {isTextarea ? (
        <textarea
          className={
            "block bg-transparent rounded-lg px-2.5 pb-2 pt-4 w-full font-light text-sm text-black border border-gray-300 focus:outline-none peer"
          }
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          rows={rows}
          placeholder=" " // Add placeholder with a space
          ref={inputRef}
          onFocus={handleInputFocus}
          style={autofillStyle} // Apply style to textarea too
          autoComplete="new-password"
        />
      ) : isSelect ? (
        <select
          id={`floating_outlined_${id}`}
          className={
            "block bg-transparent rounded-lg px-2.5 pb-2 pt-4 font-light w-full text-sm text-black border border-gray-300 focus:outline-none peer"
          }
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder=" " // Add placeholder with a space
          ref={inputRef}
          onFocus={handleInputFocus}
          style={autofillStyle} // Apply style to select too
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <>
          <input
            type={type === "password" && showPassword ? "text" : type}
            id={id}
            name={name}
            className={
              "block bg-transparent rounded-lg px-2.5 pb-2 pt-4 w-full text-sm text-black border border-gray-300 focus:outline-none peer"
            }
            value={value}
            onChange={onChange}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            autoComplete="new-password"
            onClick={onClick}
            readOnly={readOnly}
            onBlur={onBlur}
            placeholder=" " // Add placeholder with a space
            ref={ref || inputRef} // Use the passed ref or the internal inputRef
            onFocus={handleInputFocus}
            style={autofillStyle} // Apply the autofill style here
          />
          {type === "password" && (
            <span
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <EyeOutlined className="h-4 w-4 text-[#83868f]" />
              ) : (
                <EyeInvisibleOutlined className="h-4 w-4 text-[#83868f]" />
              )}
            </span>
          )}
        </>
      )}
      <label
        htmlFor={`floating_outlined_${id}`}
        className={`${backgroundColor} absolute font-medium text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 cursor-pointer`}
        style={{ pointerEvents: 'none' }} // Add this style
      >
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      {prefix && (
        <span className="absolute left-4 top-1/2 transform -translate-y-1/2">
          {prefix}
        </span>
      )}
      {suffix && (
        <span className="absolute right-4 top-1/2 transform -translate-y-1/2">
          {suffix}
        </span>
      )}
    </div>
  );
});

export default InputField;