export const translationNavigation = {
    da: {
        conversations: 'Samtaler',
        uploadData: 'Upload information',
        statistics: 'Statistik',
        logout: 'Log ud',
        allChatbots: 'Alle Chatbots',
        Chatbot: 'Chatbot',
        Crm: 'CRM',
        users: 'Brugere',
    },
    sv: {
        conversations: 'Samtal',
        uploadData: 'Ladda upp information',
        statistics: 'Statistik',
        logout: 'Logga ut',
        allChatbots: 'Alla Chatbots',
        Chatbot: 'Chatbot',
        Crm: 'CRM',
        users: 'Användare',
    },
    no: {
        conversations: 'Samtaler',
        uploadData: 'Last opp informasjon',
        statistics: 'Statistikk',
        logout: 'Logg ut',
        allChatbots: 'Alle Chatbots',
        Chatbot: 'Chatbot',
        Crm: 'CRM',
        users: 'Brukere',
    },
    en: {
        conversations: 'Conversations',
        uploadData: 'Upload Information',
        statistics: 'Statistics',
        logout: 'Log Out',
        allChatbots: 'All Chatbots',
        Chatbot: 'Chatbot',
        Crm: 'CRM',
        users: 'Users',
    },
};

export const translationUploadData = {
    da: {
        expirationPicker: 'Dato og Tid',
        errorFetchingIndexes: 'Fejl ved hentning af indeks. Prøv igen.',
        errorFetchingData: 'Fejl ved hentning af data. Prøv igen.',
        errorConversations: 'Fejl ved hentning af samtaler. Prøv igen.',
        tokenChatbotMissing: 'Token eller chatbotID ikke fundet',
        convoResolved: 'Samtale markeret som løst.',
        errorUpdatingConvo: 'Fejl ved opdatering af samtale.',
        titleRequired: 'Titel er påkrævet.',
        titleLimit: 'Titlen må ikke overstige 255 tegn.',
        tokenNotFound: 'Token ikke fundet.',
        errorUpdatingData: 'Fejl ved opdatering af data.',
        dataUpdated: 'Data updated in',
        dataAdded: 'Data added to',
        errorUpdating: 'Error updating data in',
        errorAdding: 'Error adding data to',
        dataDeletedFrom: 'Data deleted from',
        errorDeletingFrom: 'Error deleting data from',
        dataUploaded: 'Data uploaded to',
        errorUploadingTo: 'Error uploading to',
        convoMarkedResolved: '. Samtale markeret som løst.',
        myUploadedInfo: 'Min Uploadede Info',
        lackingInfoConvos: 'Samtaler med Manglende Info',
        conversationDetails: 'Samtale Detaljer',
        uploadDataHeader: 'Upload og Håndter Information',
        noConversationSelected: 'Ingen samtale valgt.',
        deleteLabel: 'Slet',
        conversationID: 'Samtale ID',
        dateLabel: 'Dato',
        topicLabel: 'Emne',
        uncategorized: 'Ikke kategoriseret',
        requiredTitlePlaceholder: 'Indtast en titel for din information',
        uploadInfoPlaceholder: 'Indtast information du vil uploade',
        updating: 'Opdaterer...',
        uploading: 'Uploader...',
        update: 'Opdater',
        upload: 'Upload',
        cancel: 'Annuller',
        removing: 'Markerer...',
        remove: 'Fjern',
        addNotesHere: 'Tilføj noter her...',
        dataDeletedMessage: 'Data deleted from',
        errorDeletingMessage: 'Error deleting data from',
        expiresAt: 'Udløber',
    },
    sv: {
        expirationPicker: 'Datum och Tid',
        errorFetchingIndexes: 'Fel vid hämtning av index. Försök igen.',
        errorFetchingData: 'Fel vid hämtning av data. Försök igen.',
        errorConversations: 'Fel vid hämtning av samtal. Försök igen.',
        tokenChatbotMissing: 'Token eller chatbotID saknas',
        convoResolved: 'Samtal markerad som löst.',
        errorUpdatingConvo: 'Fel vid uppdatering av samtal.',
        titleRequired: 'Titel krävs.',
        titleLimit: 'Titeln får inte överskrida 255 tecken.',
        tokenNotFound: 'Ingen token hittades.',
        errorUpdatingData: 'Fel vid uppdatering av data.',
        dataUpdated: 'Data uppdaterad i',
        dataAdded: 'Data tillagd i',
        errorUpdating: 'Fel vid uppdatering av data i',
        errorAdding: 'Fel vid tillägg av data i',
        dataDeletedFrom: 'Data raderad från',
        errorDeletingFrom: 'Fel vid radering av data från',
        dataUploaded: 'Data uppladdad till',
        errorUploadingTo: 'Fel vid uppladdning till',
        convoMarkedResolved: '. Samtal markerad som löst.',
        myUploadedInfo: 'Min uppladdade info',
        lackingInfoConvos: 'Samtal med saknad info',
        conversationDetails: 'Samtalsdetaljer',
        uploadDataHeader: 'Ladda upp och hantera information',
        noConversationSelected: 'Inget samtal valt.',
        deleteLabel: 'Radera',
        conversationID: 'Samtals-ID',
        dateLabel: 'Datum',
        topicLabel: 'Ämne',
        uncategorized: 'Ej kategoriserat',
        requiredTitlePlaceholder: 'Ange en titel för din information',
        uploadInfoPlaceholder: 'Ange information du vill ladda upp',
        updating: 'Uppdaterar...',
        uploading: 'Laddar upp...',
        update: 'Uppdatera',
        upload: 'Ladda upp',
        cancel: 'Avbryt',
        removing: 'Tar bort...',
        remove: 'Ta bort',
        addNotesHere: 'Lägg till anteckningar här...',
        dataDeletedMessage: 'Data raderad från',
        errorDeletingMessage: 'Fel vid radering av data från',
        expiresAt: 'Utgår',
    },
    no: {
        expirationPicker: 'Dato og Tid',
        errorFetchingIndexes: 'Feil ved henting av indeks. Prøv på nytt.',
        errorFetchingData: 'Feil ved henting av data. Prøv på nytt.',
        errorConversations: 'Feil ved henting av samtaler. Prøv på nytt.',
        tokenChatbotMissing: 'Token eller chatbotID ikke funnet',
        convoResolved: 'Samtale markert som løst.',
        errorUpdatingConvo: 'Feil ved oppdatering av samtale.',
        titleRequired: 'Tittel er påkrevd.',
        titleLimit: 'Tittelen kan ikke overstige 255 tegn.',
        tokenNotFound: 'Ingen token funnet.',
        errorUpdatingData: 'Feil ved oppdatering av data.',
        dataUpdated: 'Data oppdatert i',
        dataAdded: 'Data lagt til i',
        errorUpdating: 'Feil ved oppdatering av data i',
        errorAdding: 'Feil ved å legge til data i',
        dataDeletedFrom: 'Data slettet fra',
        errorDeletingFrom: 'Feil ved sletting av data fra',
        dataUploaded: 'Data lastet opp til',
        errorUploadingTo: 'Feil ved opplasting til',
        convoMarkedResolved: '. Samtale markert som løst.',
        myUploadedInfo: 'Min opplastede info',
        lackingInfoConvos: 'Samtaler med manglende info',
        conversationDetails: 'Samtaledetaljer',
        uploadDataHeader: 'Last opp og håndter informasjon',
        noConversationSelected: 'Ingen samtale valgt.',
        deleteLabel: 'Slett',
        conversationID: 'Samtale-ID',
        dateLabel: 'Dato',
        topicLabel: 'Emne',
        uncategorized: 'Ikke kategorisert',
        requiredTitlePlaceholder: 'Skriv inn en tittel for informasjonen din',
        uploadInfoPlaceholder: 'Skriv inn informasjonen du vil laste opp',
        updating: 'Oppdaterer...',
        uploading: 'Laster opp...',
        update: 'Oppdater',
        upload: 'Last opp',
        cancel: 'Avbryt',
        removing: 'Fjerner...',
        remove: 'Fjern',
        addNotesHere: 'Legg til notater her...',
        dataDeletedMessage: 'Data slettet fra',
        errorDeletingMessage: 'Feil ved sletting av data fra',
        expiresAt: 'Utløper',
    },
    en: {
        expirationPicker: 'Date & Time',
        errorFetchingIndexes: 'Error fetching indexes. Please try again.',
        errorFetchingData: 'Error fetching data. Please try again.',
        errorConversations: 'Error fetching conversations. Please try again.',
        tokenChatbotMissing: 'Token or chatbotID not found',
        convoResolved: 'Conversation marked as resolved.',
        errorUpdatingConvo: 'Error updating conversation.',
        titleRequired: 'Title is required.',
        titleLimit: 'Title cannot exceed 255 characters.',
        tokenNotFound: 'Token not found.',
        errorUpdatingData: 'Error updating data.',
        dataUpdated: 'Data updated in',
        dataAdded: 'Data added to',
        errorUpdating: 'Error updating data in',
        errorAdding: 'Error adding data to',
        dataDeletedFrom: 'Data deleted from',
        errorDeletingFrom: 'Error deleting data from',
        dataUploaded: 'Data uploaded to',
        errorUploadingTo: 'Error uploading to',
        convoMarkedResolved: '. Conversation marked as resolved.',
        myUploadedInfo: 'My Uploaded Info',
        lackingInfoConvos: 'Conversations Missing Info',
        conversationDetails: 'Conversation Details',
        uploadDataHeader: 'Upload and Manage Information',
        noConversationSelected: 'No conversation selected.',
        deleteLabel: 'Delete',
        conversationID: 'Conversation ID',
        dateLabel: 'Date',
        topicLabel: 'Topic',
        uncategorized: 'Uncategorized',
        requiredTitlePlaceholder: 'Enter a title for your information',
        uploadInfoPlaceholder: 'Enter the information you want to upload',
        updating: 'Updating...',
        uploading: 'Uploading...',
        update: 'Update',
        upload: 'Upload',
        cancel: 'Cancel',
        removing: 'Removing...',
        remove: 'Remove',
        addNotesHere: 'Add notes here...',
        dataDeletedMessage: 'Data deleted from',
        errorDeletingMessage: 'Error deleting data from',
        expiresAt: 'Expires',
    },
};

export const translationsDashboard = {
    da: {
        loadingConversations: 'Loader Samtaler...',
        sidebarTitle: 'Samtaler',
        showFilters: 'Vis filtre',
        hideFilters: 'Skjul filtre',
        noToken: 'Token ikke fundet',
        bugStatus: 'Fejlstatus',
        customerRating: 'Kunde vurdering',
        uncategorized: 'Ikke kategoriseret samtale',
        noConvoSelected: 'Vælg en samtale for at se detaljerne.',
        reported: 'rapporteret',
        resolved: 'løst',
        noStatus: 'ingen',
        markResolved: 'Markér som løst',
        removeStatus: 'Fjern fejlstatus',
        reportBug: 'Rapportér fejl',
        notesLabel: 'Noter',
        saveNotes: 'Gem Noter',
        userRating: 'Bruger vurdering',
        purchaseYes: 'Denne bruger foretog et køb.',
        purchaseNo: 'Denne bruger har ikke foretaget et køb.',
        all: 'Alle',
        date: 'Dato',
        conversationID: 'Samtale ID',
        showBugReport: 'Vis fejlrapport',
        hideBugReport: 'Skjul fejlrapport',
        translateButton: 'Oversæt',
        translatingButton: 'Oversætter...',
        selectLanguageOption: 'Vælg sprog',
    },
    sv: {
        loadingConversations: 'Laddar samtal...',
        sidebarTitle: 'Samtal',
        showFilters: 'Visa filter',
        hideFilters: 'Dölj filter',
        noToken: 'Ingen token hittades',
        bugStatus: 'Felstatus',
        customerRating: 'Kundbetyg',
        uncategorized: 'Ej kategoriserat samtal',
        noConvoSelected: 'Välj ett samtal för att se detaljer.',
        reported: 'rapporterad',
        resolved: 'löste',
        noStatus: 'ingen',
        markResolved: 'Markera som löst',
        removeStatus: 'Ta bort felstatus',
        reportBug: 'Rapportera fel',
        notesLabel: 'Anteckningar',
        saveNotes: 'Spara Anteckningar',
        userRating: 'Användarbetyg',
        purchaseYes: 'Denna användare gjorde ett köp.',
        purchaseNo: 'Denna användare har inte gjort något köp.',
        all: 'Alla',
        date: 'Datum',
        conversationID: 'Samtals-ID',
        showBugReport: 'Visa felrapport',
        hideBugReport: 'Dölj felrapport',
        translateButton: 'Översätt',
        translatingButton: 'Översätter...',
        selectLanguageOption: 'Välj språk',
    },
    no: {
        loadingConversations: 'Laster samtaler...',
        sidebarTitle: 'Samtaler',
        showFilters: 'Vis filtre',
        hideFilters: 'Skjul filtre',
        noToken: 'Ingen token funnet',
        bugStatus: 'Feilstatus',
        customerRating: 'Kunde vurdering',
        uncategorized: 'Ikke kategorisert samtale',
        noConvoSelected: 'Velg en samtale for å se detaljene.',
        reported: 'rapportert',
        resolved: 'løst',
        noStatus: 'ingen',
        markResolved: 'Marker som løst',
        removeStatus: 'Fjern feilstatus',
        reportBug: 'Rapporter feil',
        notesLabel: 'Notater',
        saveNotes: 'Lagre Notater',
        userRating: 'Brukervurdering',
        purchaseYes: 'Denne brukeren gjorde et kjøp.',
        purchaseNo: 'Denne brukeren har ikke gjort et kjøp.',
        all: 'Alle',
        date: 'Dato',
        conversationID: 'Samtale-ID',
        showBugReport: 'Vis feilrapport',
        hideBugReport: 'Skjul feilrapport',
        translateButton: 'Oversett',
        translatingButton: 'Oversetter...',
        selectLanguageOption: 'Velg språk',
    },
    en: {
        loadingConversations: 'Loading Conversations...',
        sidebarTitle: 'Conversations',
        showFilters: 'Show Filters',
        hideFilters: 'Hide Filters',
        noToken: 'No token found',
        bugStatus: 'Bug Status',
        customerRating: 'Customer Rating',
        uncategorized: 'Uncategorized conversation',
        noConvoSelected: 'Select a conversation to view its details.',
        reported: 'reported',
        resolved: 'resolved',
        noStatus: 'none',
        markResolved: 'Mark as resolved',
        removeStatus: 'Remove bug status',
        reportBug: 'Report Bug',
        notesLabel: 'Notes',
        saveNotes: 'Save Notes',
        userRating: 'User Rating',
        purchaseYes: 'This user made a purchase.',
        purchaseNo: 'This user did not make a purchase.',
        all: 'All',
        date: 'Date',
        conversationID: 'Conversation ID',
        showBugReport: 'Show bug report',
        hideBugReport: 'Hide bug report',
        translateButton: 'Translate',
        translatingButton: 'Translating...',
        selectLanguageOption: 'Language',
    },
};

export const translationStatistics = {
    da: {
        tokenMissing: 'Token eller chatbotID ikke fundet',
        choosePeriod: 'Vælg tidsperiode',
        allTimes: 'Alle Tider',
        last7Days: 'Seneste 7 Dage',
        last30Days: 'Seneste 30 Dage',
        yesterday: 'I går',
        customRange: 'Brugerdefineret',

        insights: 'Indsigter',
        totalMessages: 'Total antal beskeder',
        avgMessagesPerDay: 'Gennemsnitlig antal beskeder per dag',
        totalConversations: 'Total antal samtaler',
        totalUserRatings: 'Total antal bruger vurderinger',
        averageRating: 'Gennemsnitlig bruger vurdering',

        dailyMessages: 'Daglige beskeder',
        loadingDailyData: 'Loader daglig data...',
        topicMessages: 'Samtaler fordelt på emne',
        loadingTopicData: 'Loader emne data...',
        timeOfDay: 'Tidspunkt på dagen',
        loadingHourlyData: 'Loader timelig data...',

        dateLabel: 'Dato',
        messageCount: 'Antal beskeder',
        topicLabel: 'Emne',
        conversationCount: 'Antal samtaler',
        timeLabel: 'Tid',
    },
    en: {
        tokenMissing: 'Token or chatbotID not found',
        choosePeriod: 'Choose time period',
        allTimes: 'All Times',
        last7Days: 'Last 7 Days',
        last30Days: 'Last 30 Days',
        yesterday: 'Yesterday',
        customRange: 'Custom',

        insights: 'Insights',
        totalMessages: 'Total Messages',
        avgMessagesPerDay: 'Average Messages per Day',
        totalConversations: 'Total Conversations',
        totalUserRatings: 'Total User Ratings',
        averageRating: 'Average User Rating',

        dailyMessages: 'Daily Messages',
        loadingDailyData: 'Loading daily data...',
        topicMessages: 'Conversations by Topic',
        loadingTopicData: 'Loading topic data...',
        timeOfDay: 'Time of Day',
        loadingHourlyData: 'Loading hourly data...',

        dateLabel: 'Date',
        messageCount: 'Message Count',
        topicLabel: 'Topic',
        conversationCount: 'Conversations',
        timeLabel: 'Time',
    },
};

export const translationCRM = {
    da: {
        loading: 'Indlæser CRM data...',
        sidebarTitle: 'CRM',
        sidebarSubtitle: 'Oversigt over brugere og deres interaktioner',
        rawData: 'Rå data',
        stats: 'Statistik',
        noData: 'Ingen CRM data tilgængelig.',
        visitedAt: 'Besøgt (tidspunkt)',
        usedChatbot: 'Brugt Chatbot',
        purchaseMade: 'Foretaget køb',
        chatbotID: 'Chatbot ID',
        userID: 'Website User ID',
        totalVisitors: 'Totale besøgende',
        totalPurchases: 'Totale køb',
        chatbotUsers: 'Antal der klikkede på chatbot',
        chatbotPurchases: 'Antal der klikkede på chatbot og købte',
        actuallyUsed: 'Brugte Chatbot',
        actuallyUsedPurchases: 'Antal der brugte chatbot og købte',
        convRate: 'Samlet konverteringsrate',
        chatbotConvRate: 'Konverteringsrate klikkede på chatbot',
        actuallyUsedConvRate: 'Konverteringsrate brugte chatbot',
        nonChatbotConvRate: 'Konverteringsrate klikkede ikke på chatbot',
        yes: 'Ja',
        no: 'Nej',
    },
    sv: {
        loading: 'Laddar CRM-data...',
        sidebarTitle: 'CRM',
        sidebarSubtitle: 'Översikt över användare och deras interaktioner',
        rawData: 'Rådata',
        stats: 'Statistik',
        noData: 'Ingen CRM-data tillgänglig.',
        visitedAt: 'Besökt (tidpunkt)',
        usedChatbot: 'Använde Chatbot',
        purchaseMade: 'Genomförde köp',
        chatbotID: 'Chatbot-ID',
        userID: 'Webbplatsanvändar-ID',
        totalVisitors: 'Totalt antal besökare',
        totalPurchases: 'Totala köp',
        chatbotUsers: 'Antal som klickade på chatbot',
        chatbotPurchases: 'Antal som klickade på chatbot och köpte',
        actuallyUsed: 'Använde Chatbot',
        actuallyUsedPurchases: 'Antal som använde chatbot och köpte',
        convRate: 'Total konverteringsgrad',
        chatbotConvRate: 'Konverteringsgrad klickade på chatbot',
        actuallyUsedConvRate: 'Konverteringsgrad använde chatbot',
        nonChatbotConvRate: 'Konverteringsgrad klickade inte på chatbot',
        yes: 'Ja',
        no: 'Nej',
    },
    no: {
        loading: 'Laster CRM-data...',
        sidebarTitle: 'CRM',
        sidebarSubtitle: 'Oversikt over brukere og deres interaksjoner',
        rawData: 'Rådata',
        stats: 'Statistikk',
        noData: 'Ingen CRM-data tilgjengelig.',
        visitedAt: 'Besøkt (tidspunkt)',
        usedChatbot: 'Brukt Chatbot',
        purchaseMade: 'Gjennomførte kjøp',
        chatbotID: 'Chatbot-ID',
        userID: 'Nettstedets bruker-ID',
        totalVisitors: 'Totale besøkende',
        totalPurchases: 'Totale kjøp',
        chatbotUsers: 'Antall som klikket på chatbot',
        chatbotPurchases: 'Antall som klikket på chatbot og kjøpte',
        actuallyUsed: 'Brukte Chatbot',
        actuallyUsedPurchases: 'Antall som brukte chatbot og kjøpte',
        convRate: 'Samlet konverteringsrate',
        chatbotConvRate: 'Konverteringsrate klikket på chatbot',
        actuallyUsedConvRate: 'Konverteringsrate brukte chatbot',
        nonChatbotConvRate: 'Konverteringsrate klikket ikke på chatbot',
        yes: 'Ja',
        no: 'Nei',
    },
    en: {
        loading: 'Loading CRM data...',
        sidebarTitle: 'CRM',
        sidebarSubtitle: 'Overview of users and their interactions',
        rawData: 'Raw Data',
        stats: 'Statistics',
        noData: 'No CRM data available.',
        visitedAt: 'Visited (timestamp)',
        usedChatbot: 'Used Chatbot',
        purchaseMade: 'Purchase Made',
        chatbotID: 'Chatbot ID',
        userID: 'Website User ID',
        totalVisitors: 'Total Visitors',
        totalPurchases: 'Total Purchases',
        chatbotUsers: 'Number Who Clicked Chatbot',
        chatbotPurchases: 'Number Who Clicked Chatbot and Purchased',
        actuallyUsed: 'Used Chatbot',
        actuallyUsedPurchases: 'Number Who Used Chatbot and Purchased',
        convRate: 'Overall Conversion Rate',
        chatbotConvRate: 'Conversion Rate (Clicked Chatbot)',
        actuallyUsedConvRate: 'Conversion Rate (Used Chatbot)',
        nonChatbotConvRate: 'Conversion Rate (Did Not Click Chatbot)',
        yes: 'Yes',
        no: 'No',
    },
}

