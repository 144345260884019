import React, { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FilterDropdown from '../../components/FilterDropdown';
import { translationsDashboard } from '../../utils/translations';
import { responsiveScreen } from '../../utils/general';
import ConversationCard from '../../components/ConversationCard';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { FaLanguage } from 'react-icons/fa';
import { Skeleton } from 'antd';

const translations = translationsDashboard;
const responsive = responsiveScreen;

function Dashboard({ selectedChatbotId , isSidebarOpen }) {

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('appLanguage') || 'da';
    });

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'appLanguage' && e.newValue) {
                setLanguage(e.newValue);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const t = (key) => translations[language]?.[key] || key;
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [conversationLoading, setConversationLoading] = useState(false); // Added state for conversation loading
    const [notes, setNotes] = useState('');
    const [showBugReport, setShowBugReport] = useState(false);

    const [targetLanguage, setTargetLanguage] = useState('');
    const [isTranslating, setIsTranslating] = useState(false);

    const [fejlstatusFilter, setFejlstatusFilter] = useState('');
    const [customerRatingFilter, setCustomerRatingFilter] = useState('');
    const [emneFilter, setEmneFilter] = useState('');
    const [emneOptions, setEmneOptions] = useState([]);

    const isAdmin = localStorage.getItem('is_admin') === 'true';


    const API_URL = 'https://egendatabasebackend.onrender.com';

    useEffect(() => {
        setNotes(selectedConversation?.notes || '');
    }, [selectedConversation]);

    useEffect(() => {
        const uniqueEmneOptions = [
            ...new Set(conversations.map((conv) => conv.emne)),
        ].filter(Boolean);
        setEmneOptions(uniqueEmneOptions);
    }, [conversations]);

    const show_purchase = localStorage.getItem('show_purchase');
    const filteredConversations = conversations.filter((conversation) => {
        const matchBugStatus = fejlstatusFilter === '' || conversation.bug_status === fejlstatusFilter;
        const matchRating = customerRatingFilter === '' || conversation.customer_rating === parseInt(customerRatingFilter, 10);
        const matchEmne = emneFilter === '' || conversation.emne === emneFilter;
        return matchBugStatus && matchRating && matchEmne;
    });

    const handleBugReport = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error(t('noToken'));
            return;
        }

        let newBugStatus;
        if (
            selectedConversation.bug_status === t('noStatus') ||
            !selectedConversation.bug_status ||
            selectedConversation.bug_status === 'ingen'
        ) {
            newBugStatus = t('reported');
        } else if (
            selectedConversation.bug_status === t('reported') ||
            selectedConversation.bug_status === 'rapporteret'
        ) {
            newBugStatus = t('resolved');
        } else if (
            selectedConversation.bug_status === t('resolved') ||
            selectedConversation.bug_status === 'løst'
        ) {
            newBugStatus = t('noStatus');
        }

        try {
            const response = await axios.patch(
                `${API_URL}/conversations/${selectedConversation.id}`,
                { bug_status: newBugStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSelectedConversation(response.data);
            setConversations((prev) =>
                prev.map((conv) => (conv.id === response.data.id ? response.data : conv))
            );
        } catch (error) {
            console.error('Error updating bug status:', error);
        }
    };

    const handleSaveNotes = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error(t('noToken'));
            return;
        }

        try {
            const response = await axios.patch(
                `${API_URL}/conversations/${selectedConversation.id}`,
                { notes },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSelectedConversation(response.data);
            setConversations((prev) =>
                prev.map((conv) => (conv.id === response.data.id ? response.data : conv))
            );
        } catch (error) {
            console.error('Error saving notes:', error);
        }
    };

    useEffect(() => {
        const getConversations = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            const storedChatbotIds = localStorage.getItem('chatbot_ids');
            const chatbotIds = storedChatbotIds ? JSON.parse(storedChatbotIds) : [];

            if (!token || chatbotIds.length === 0) {
                setLoading(false);
                return;
            }

            const params = {};
            if (selectedChatbotId === 'ALL') {
                params.chatbot_id = chatbotIds.join(',');
            } else {
                params.chatbot_id = selectedChatbotId;
            }

            try {
                const response = await axios.get(`${API_URL}/conversations-metadata`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                });

                const sortedConversations = response.data.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                setConversations(sortedConversations);
            } catch (error) {
                console.error('Error fetching conversations:', error);
            } finally {
                setLoading(false);
            }
        };

        getConversations();
    }, [selectedChatbotId]);

    useEffect(() => {
        const messagesContainer = document.querySelector('.conversation-messages');
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }, [selectedConversation, conversations]);

    const handleConversationClick = async (conversation) => {
        setConversationLoading(true);
        const token = localStorage.getItem('token');
        if (!token || !conversation.id) {
            setConversationLoading(false);
            return;
        }

        try {
            const response = await axios.get(
                `${API_URL}/conversation/${conversation.id}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            const fullConversation = response.data;
            if (typeof fullConversation.conversation_data === 'string') {
                fullConversation.conversation_data = JSON.parse(
                    fullConversation.conversation_data
                );
            }

            const crmResponse = await axios.post(`${API_URL}/crm-data-for-user`, {
                user_id: fullConversation.user_id,
                chatbot_id: fullConversation.chatbot_id,
            });

            const crmRecord = crmResponse.data;
            fullConversation.madePurchase = crmRecord.madepurchase;

            setSelectedConversation(fullConversation);
            if (isMobile) {
                setShowMobileChat(true);
            }
        } catch (error) {
            console.error('Error loading conversation details:', error);
        } finally {
            setConversationLoading(false);
        }
    };

    const postProcessTranslation = (text) => {
        return text.replace(/ÅÅÅ/g, 'YYY');
    };

    const translateText = async (text, target) => {
        try {
            const response = await fetch(
                `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCrvOhuWy4upHRM4LdJR0U9dcp4Ni0xvgw`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        q: text,
                        target: target,
                    }),
                }
            );
            const data = await response.json();
            if (data && data.data && data.data.translations) {
                return postProcessTranslation(data.data.translations[0].translatedText);
            }
        } catch (err) {
            console.error('Translation error:', err);
        }
        return text;
    };

    const handleTranslateConversation = async () => {
        if (!targetLanguage || !selectedConversation) return;

        setIsTranslating(true);
        const updatedConversation = { ...selectedConversation };
        const updatedMessages = await Promise.all(
            updatedConversation.conversation_data.map(async (msg) => {
                const newText = await translateText(msg.text, targetLanguage);
                return { ...msg, text: newText };
            })
        );

        updatedConversation.conversation_data = updatedMessages;
        setSelectedConversation(updatedConversation);
        setIsTranslating(false);
    };

    const handleDeleteConversation = async (conversationId) => {
        const token = localStorage.getItem('token');
        if (!token) return;

        try {
            await axios.delete(`${API_URL}/conversations/${conversationId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setConversations((prev) => prev.filter((c) => c.id !== conversationId));
            if (selectedConversation?.id === conversationId) {
                setSelectedConversation(null);
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const languageOptions = [
        { value: '', label: t('selectLanguageOption') },
        { value: 'en', label: 'English' },
        { value: 'da', label: 'Dansk' },
        { value: 'sv', label: 'Svenska' },
        { value: 'no', label: 'Norsk' },
        { value: 'de', label: 'Deutsch' },
        { value: 'fr', label: 'Français' },
        { value: 'es', label: 'Español' },
    ];

    const renderMessageContent = (text) => {
        text = text.replace(/<br>!/g, '');
        let cleanText = text.replace(/(YYY|YXY)([\s\S]*?)XXX/g, (match, p1, p2) => {
            return p1 + p2.replace(/\n|<br\s*\/?>/g, ' ') + 'XXX';
        });
        cleanText = cleanText.replace(/<br\s*\/?>\s*(?=XXX)/g, '');
        cleanText = cleanText.replace(/YYY\s*(\n|<br\s*\/?>)+/g, 'YYY');

        const youtubeEmbedPattern = /https?:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/g;
        cleanText = cleanText.replace(youtubeEmbedPattern, (match, videoId) => {
            return `
        <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; background: #000; margin: 0.5em auto;">
          <iframe 
            src="https://www.youtube.com/embed/${videoId}?fs=0"
            frameborder="0"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
          ></iframe>
        </div>
      `;
        });

        const isImageUrl = (url) =>
            /\.(jpeg|jpg|JPG|gif|png|webp|bmp|tiff?)$/.test(url);

        const processLinksAndFormatting = (content, isBoxContent) => {
            content = content.replace(
                /(https?:\/\/[^\s<]+)\s*\n\s*(\S+)/g,
                (m, p1, p2) => p1 + p2
            );
            content = content.replace(
                /\[([^\]]+)\]\(([^)]+)\)/g,
                (m, linkText, linkUrl) => {
                    const encodedUrl = encodeURI(linkUrl);
                    if (isImageUrl(encodedUrl)) {
                        return `<img src="${encodedUrl}" alt="${linkText}" 
              style="max-width: 100%; height: auto; margin: 10px 0; display: block; margin-left: auto; margin-right: auto;" />`;
                    }
                    return isBoxContent
                        ? `<span class="product-link" data-url="${encodedUrl}">${linkText}</span>`
                        : `<a href="${encodedUrl}" target="_blank" rel="noreferrer">${linkText}</a>`;
                }
            );
            content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

            content = content.replace(
                /(https?:\/\/[^\s<]+\.(jpeg|jpg|JPG|gif|png|webp|bmp|tiff?)(\?[^<>\s]*)?)/gi,
                (matchVal) => {
                    const encodedUrl = encodeURI(matchVal);
                    return `<img src="${encodedUrl}" alt="Product Image" 
            style="max-width: 100%; height: auto; margin: 10px 0; display: block; margin-left: auto; margin-right: auto;" />`;
                }
            );
            return content;
        };

        const productBlockPattern = /XXX([\s\S]*?)YYY/g;
        const productSeparatorPattern = /YXY/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = productBlockPattern.exec(cleanText)) !== null) {
            if (match.index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: processLinksAndFormatting(
                        cleanText.substring(lastIndex, match.index),
                        false
                    ),
                });
            }
            const productSections = match[1]
                .split(productSeparatorPattern)
                .map((p) => p.trim());
            if (productSections.length > 0) {
                parts.push({
                    type: 'product',
                    content: productSections,
                });
            }
            lastIndex = productBlockPattern.lastIndex;
        }

        if (lastIndex < cleanText.length) {
            parts.push({
                type: 'text',
                content: processLinksAndFormatting(
                    cleanText.substring(lastIndex),
                    false
                ),
            });
        }
        return parts;
    };

    const renderProductCarousel = (productSections) => {
        const renderProductBox = (product, index) => {
            const linkPattern = /https?:\/\/[^\s<]+/g;
            const linkMatches = product.match(linkPattern) || [];
            const productLink =
                linkMatches.length > 0
                    ? linkMatches[linkMatches.length - 1].replace(/['">]/g, '')
                    : '#';

            const cleanedProductContent = product
                .replace(linkPattern, (url) => (url === productLink ? '' : url))
                .replace(/\*\*(.*?)\*\*/g, '$1')  // Add this line
                .replace(
                    /(https?:\/\/[^\s<]+\.(jpeg|jpg|JPG|gif|png|webp|bmp|tiff?)(\?[^<>\s]*)?)/gi,
                    '<img src="$1" style="max-width: 100%; height: auto; margin: 10px 0; display: block; margin-left: auto; margin-right: auto;" />'
                );

            return (
                <div key={index} className={`p-0 border ${selectedConversation?.conversation_data?.isUser ? "bg-[#686BF1] text-white" : " bg-white"} border-gray-300 rounded-2xl mb-5 text-center flex flex-col justify-between w-full h-auto`}>
                    <div className="max-w-full h-auto my-2 block mx-auto">{parse(cleanedProductContent)}</div>
                    <a
                        href={productLink}
                        className={`inline-block  ${selectedConversation?.conversation_data?.isUser ? " text-[#212023] bg-gradient-to-b from-white to-[#EDEEF9]" : "bg-gradient-to-b from-[#777BFF] to-[#686BF1] text-white"} shadow-[0px_4px_8px_0px_#686BF152] text-sm px-5 py-2.5 no-underline rounded-2xl m-2.5`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        SE PRODUKT
                    </a>
                </div>
            );
        };

        if (productSections.length === 1) {
            return <div>{renderProductBox(productSections[0], 0)}</div>;
        }

        return (
            <Carousel responsive={responsive}>
                {productSections.map((product, i) => renderProductBox(product, i))}
            </Carousel>
        );
    };

    const [showMobileChat, setShowMobileChat] = useState(false);

    const isMobile = window.innerWidth <= 1024;

    const handleCardClick = async (conversation) => {
        await handleConversationClick(conversation);
    };

    const handleBackToConversationList = () => {
        setShowMobileChat(false);
        setSelectedConversation(null);
    };

    return (
        <div className={`h-full flex w-full lg:rounded-3xl ${isMobile && showMobileChat ? 'flex-col' : 'lg:flex'}`}>
            {(!isMobile || !showMobileChat) && (
                <div className={`mt-10 lg:mt-0 min-w-[348px] flex flex-col gap-y-4 px-6 py-6 ${isMobile ? 'w-full' : ''}`}>
                    <div className='flex justify-between items-center gap-3'>
                        <h2 className='text-[#212023] leading-0 mb-0 text-base lg:text-2xl font-bold lg:font-medium'>{t('sidebarTitle')}</h2>
                    </div>
                    <div className='-mt-10 lg:mt-0'>
                        <FilterDropdown
                            t={t}
                            setFejlstatusFilter={setFejlstatusFilter}
                            setCustomerRatingFilter={setCustomerRatingFilter}
                            setEmneFilter={setEmneFilter}
                            emneOptions={emneOptions}
                            fejlstatusFilter={fejlstatusFilter}
                            customerRatingFilter={customerRatingFilter}
                            emneFilter={emneFilter}
                        />
                    </div>

                    <div className={`h-[calc(100vh-170px)] gap-y-2 flex flex-col overflow-y-auto pr-2 ${isMobile ? 'w-full' : ''}`}>
                        {
                            loading ? (
                                <Skeleton active />
                            ) : (
                                <>
                                    {filteredConversations.map((conversation) => (
                                        <ConversationCard
                                            key={conversation.id}
                                            conversation={conversation}
                                            handleConversationClick={() => handleCardClick(conversation)}
                                            t={t}
                                            isActive={selectedConversation?.id === conversation.id}
                                        />
                                    ))}
                                </>
                            )}
                    </div>
                </div>
            )}

            {(isMobile && !showMobileChat) ? null : (
                <div className={`relative  h-full bg-[#EEEFFA] rounded-3xl ${isSidebarOpen ? "lg:w-full" : 'w-full'} ${isMobile ? 'w-full' : ""}`}>
                    {conversationLoading ? (
                        <div className='h-full px-6 justify-start mt-10 items-center flex flex-col'>
                            <Skeleton active />
                        </div>
                    ) : selectedConversation ? (
                        <>
                            <div className='flex justify-between mt-10 lg:mt-0 items-center pt-6 px-6'>
                                <h3 className="text-[#686BF1] flex items-center lg:gap-0 gap-2 mb-0 text-base truncate lg:text-2xl font-medium">
                                    <img src='/svgs/left-arrow.svg' alt='...' onClick={handleBackToConversationList} className='block cursor-pointer lg:hidden w-6 h-6' />
                                    {selectedConversation.emne || t('uncategorized')}
                                </h3>

                                <div className="lg:border lg:border-[#DBDCEC] flex justify-center items-center p-2 rounded-full h-[40px]">
                                    <select
                                        value={targetLanguage}
                                        onChange={(e) => setTargetLanguage(e.target.value)}
                                        className='!bg-transparent text-xs outline-none max-w-24 cursor-pointer font-normal text-[#212023]'
                                    >
                                        {languageOptions.map((lang) => (
                                            <option key={lang.value} value={lang.value}>
                                                {lang.label}
                                            </option>
                                        ))}
                                    </select>
                                    <button onClick={handleTranslateConversation} className='h-6 w-6 rounded-full ml-2 flex justify-center items-center text-xs font-normal text-[#212023] bg-gradient-to-b from-white to-[#EDEEF9] shadow-[0px_2px_2px_0px_#0000001F] lg:hidden'>
                                        <FaLanguage className="text-base" />
                                    </button>
                                    <button
                                        onClick={handleTranslateConversation}
                                        className='hidden h-[32px] w-[92px] rounded-full ml-5 lg:flex justify-center items-center text-xs font-normal text-[#212023] bg-gradient-to-b from-white to-[#EDEEF9] shadow-[0px_2px_2px_0px_#0000001F]'
                                        disabled={isTranslating || !targetLanguage}
                                    >
                                        {isTranslating ? t('translatingButton') : t('translateButton')}
                                    </button>
                                </div>
                            </div>

                            <div className="flex flex-col lg:gap-y-2 gap-y-3 lg:flex-row lg:justify-between flex-wrap lg:items-center bg-[#E5E6F3] mx-6 my-2 p-3 rounded-2xl">
                                {selectedConversation.customer_rating && (
                                    <div className="text-[#212023] text-xs font-light my-2">
                                        <strong className='opacity-60 uppercase'>
                                            | {t('userRating')} {selectedConversation.customer_rating} |
                                        </strong>
                                    </div>
                                )}
                                <div className='flex gap-2 flex-row justify-between lg:flex-col text-[#212023] text-[10px] font-light'>
                                    <span className='opacity-60 uppercase'>{t('conversationID')}</span>
                                    <span>{selectedConversation.id}</span>
                                </div>
                                <div className='flex gap-2 justify-between flex-row lg:flex-col text-[#212023] text-[10px] font-light'>
                                    <span className='opacity-60'>{t('date')}</span>
                                    {new Date(selectedConversation.created_at).toLocaleString()}
                                </div>
                                <div className='flex gap-2 justify-between flex-row lg:flex-col text-[#212023] text-[10px] font-light'>
                                    <span className='opacity-60'>Bruger ID</span>
                                    {selectedConversation.user_id}
                                </div>
                                <div className='flex gap-2 justify-between flex-row lg:flex-col text-[#212023] text-[10px] font-light'>
                                    <span className='opacity-60'>Score</span>
                                    {selectedConversation.score ?? 'Ingen score'}
                                </div>
                                <button
                                    className="h-[35px] px-4 rounded-full flex justify-center items-center text-xs font-normal text-[#212023] bg-gradient-to-b from-white to-[#EDEEF9] shadow-[0px_2px_2px_0px_#0000001F]"
                                    onClick={() => setShowBugReport(!showBugReport)}
                                >
                                    {showBugReport ? t('hideBugReport') : t('showBugReport')}
                                    {showBugReport ? <EyeInvisibleFilled className='ml-2' /> : <EyeFilled className='ml-2' />}
                                </button>
                                {isAdmin && (
                                    <button
                                        style={{ backgroundColor: 'red', color: '#fff' }}
                                        onClick={() => handleDeleteConversation(selectedConversation.id)}
                                    >
                                        Delete Conversation
                                    </button>
                                )}
                            </div>

                            {/* Purchase info */}
                            {(selectedConversation.madePurchase === 'true' ||
                                selectedConversation.madePurchase === 'false') &&
                                show_purchase === 'true' && (
                                    <p
                                    className='mx-6'
                                        style={{
                                            fontWeight: 'bold',
                                            color:
                                                selectedConversation.madePurchase === 'true' ? 'green' : 'red',
                                        }}
                                    >
                                        {selectedConversation.madePurchase === 'true'
                                            ? t('purchaseYes')
                                            : t('purchaseNo')}
                                    </p>
                                )
                            }

                            <div className="conversation-messages px-6 overflow-y-auto calcHeight mr-6">
                                {selectedConversation.conversation_data.map((msg, index) => {
                                    const parts = renderMessageContent(msg.text);
                                    return parts.map((part, partIndex) => {
                                        if (part.type === 'text') {
                                            return (
                                                <div
                                                    key={`${index}-${partIndex}`}
                                                    className={`max-w-[528px] w-fit shadow-[0px_2px_6px_0px_#2B2B3214] text-sm font-light mb-6 p-4 ${msg.isUser
                                                        ? 'rounded-l-2xl rounded-tr-2xl bg-[#686BF1] text-white ml-auto'
                                                        : 'rounded-r-2xl rounded-tl-2xl bg-[#FFFFFF]'
                                                        }`}
                                                >
                                                    <div>{parse(part.content)}</div>
                                                    {msg.image && (
                                                        <img
                                                            src={msg.image}
                                                            alt="Uploaded"
                                                            style={{
                                                                maxWidth: '100%',
                                                                height: 'auto',
                                                                marginTop: '0.5em',
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            );
                                        } else if (part.type === 'product') {
                                            return (
                                                <div
                                                    key={`${index}-${partIndex}`}
                                                    className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-5 w-full"
                                                >
                                                    {renderProductCarousel(part.content)}
                                                </div>
                                            );
                                        }
                                        return null;
                                    });
                                })}
                            </div>

                            {showBugReport && (
                                <div className="absolute bottom-0 right-0 w-full z-[9999]">
                                    <div className="px-4 py-3 lg:mx-6 lg:mb-6 mb-0 shadow-[0px_2px_6px_0px_#2B2B3214] rounded-t-2xl lg:rounded-2xl bg-white border border-[#D4D5E6]">
                                        <div className="flex flex-col items-start gap-4">
                                            <span className="text-[#212023] text-sm font-normal">
                                                {t('bugStatus')}: {selectedConversation.bug_status || t('noStatus')}
                                            </span>
                                            <button
                                                className="border border-[#FF7851] text-[#FF7851] text-xs font-normal rounded-full h-[40px] w-[107px] flex justify-center items-center"
                                                onClick={handleBugReport}
                                            >
                                                {selectedConversation.bug_status === 'rapporteret'
                                                    ? t('markResolved')
                                                    : selectedConversation.bug_status === 'løst'
                                                        ? t('removeStatus')
                                                        : t('reportBug')}
                                            </button>

                                            <div className="w-full relative">
                                                {/* <h4 className="m-0 text-xs mb-4 font-normal text-[#212023]">
                                                    {t('notesLabel')}
                                                </h4> */}
                                                <div className="relative">
                                                    <textarea
                                                        value={notes}
                                                        onChange={(e) => setNotes(e.target.value)}
                                                        placeholder="Tilføj noter her..."
                                                        rows="4"
                                                        className="border border-[#DBDCEC] outline-none rounded-xl p-3 text-xs font-light text-[#212023] w-full"
                                                    />
                                                    <button
                                                        className="absolute bottom-3 right-3 shadow-[0px_4px_8px_0px_#686BF152] rounded-full h-[36px] whitespace-nowrap px-2 flex justify-center items-center text-xs font-normal text-[#FFFFFF] bg-gradient-to-b from-[#777BFF] to-[#686BF1]"
                                                        onClick={handleSaveNotes}
                                                    >
                                                        {t('saveNotes')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className='h-full justify-center items-center flex flex-col'>
                            <img src='/svgs/message-bg.svg' alt="msg" />
                            <div className="text-lg font-medium text-[#21202399] mt-2">{t('noConvoSelected')}</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Dashboard;