import React from "react";

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  return (
    <div className="bg-white text-[#212023] h-[60px] flex items-center justify-between px-4 fixed top-0 left-0 w-full z-30 lg:hidden">
      <img src="/pngs/logo.png" alt="Logo" className="h-10" />
      <img
        src="/svgs/menu.svg"
        alt="toggle"
        className="w-6 h-6 cursor-pointer"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      />
    </div>
  );
};

export default Navbar;