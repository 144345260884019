import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const CustomDropdown = ({
  value,
  options,
  onChange,
  placeholder = 'Select',
  triggerStyle = {},
  menuStyle = {},
  renderItem,
  isHidden = false,
  isCenter = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(value || options[0]?.value);

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

  const handleMenuClick = (e) => {
    const selectedValue = e.key;
    setSelectedValue(selectedValue);
    onChange(selectedValue);
  };

  const dropdownOverlay = (
    <Menu onClick={handleMenuClick} style={{ backgroundColor: '#1F2937', color: 'white', maxHeight:'300px', ...menuStyle }}>
      {options.map((option) => (
        <Menu.Item key={option.value} style={{ color: 'white' }}>
          {renderItem ? renderItem(option) : option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const selectedOption = options.find((opt) => opt.value === selectedValue) || { label: placeholder };

  return (
    <Dropdown overlay={dropdownOverlay} trigger={['click']}>
      <div
        style={{
          height: '48px',
          backgroundColor: 'transparent',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          color: 'white',
          borderRadius: '24px',
          padding: '0 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: (isHidden && isCenter) ? 'center' : 'space-between',
          cursor: 'pointer',
          ...triggerStyle,
        }}
      >
        <Tooltip title={selectedOption.label} placement={'right'}>
          {renderItem ? renderItem(selectedOption) : selectedOption.label}
        </Tooltip>
        {!isHidden && <DownOutlined style={{ color: 'white', marginLeft: '8px' }} />}
      </div>
    </Dropdown>
  );
};

export default CustomDropdown;