import React from 'react';

const ConversationCard = ({ conversation, handleConversationClick, t, isActive }) => {
    return (
        <div
            key={conversation.id}
            className={`rounded-3xl p-5 flex gap-y-2 flex-col cursor-pointer  ${conversation.bug_status === 'rapporteret' && 'bug-reported'} ${isActive ? 'bg-[#EEEFFA]' : 'border border-[#DBDCEC]'}`} 
            onClick={() => handleConversationClick(conversation)}
        >
            <span className={`text-sm font-medium ${isActive ? "text-[#686BF1]" : "text-[#212023]"}`}>
                {conversation.emne || t('uncategorized')}
            </span>
            <span className="flex justify-between items-center text-xs font-light text-[#212023]">
                <span className='opacity-60'>{t('conversationID')}: </span> {conversation.id}
            </span>
            <span className="flex justify-between items-center text-xs font-light text-[#212023]">
               <span className='opacity-60'>{t('date')}: </span> {new Date(conversation.created_at).toLocaleString()}
            </span>
            {conversation.customer_rating && (
                <span className="flex justify-between items-center text-xs font-light text-[#212023]">
                    <strong>
                        <span className='opacity-60'>{t('userRating')}</span> {conversation.customer_rating}
                    </strong>
                </span>
            )}
            {conversation.bug_status && conversation.bug_status !== 'ingen' && (
                <span className="flex justify-between items-center text-xs font-light text-[#212023]">
                   <span className='opacity-60'>{t('bugStatus')}: </span> <span className='text-[#FF7851]'>{conversation.bug_status}</span>
                </span>
            )}
        </div>
    );
};

export default ConversationCard;