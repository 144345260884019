import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { translationCRM } from '../../utils/translations';
import { Skeleton } from 'antd';

function CRM() {
  const translations = translationCRM;

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('appLanguage') || 'da';
  });

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'appLanguage') {
        setLanguage(e.newValue || 'da');
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // 4. Helper function to get translated text
  const t = (key) => translations[language]?.[key] || key;

  // States
  const [crmData, setCrmData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);

  // Default view: show statistics first
  const [view, setView] = useState('stats');

  const API_URL = 'https://egendatabasebackend.onrender.com';

  // We'll no longer read a single `chatbot_id` from localStorage.
  // We'll instead read the entire array + the user’s chosen ID (or "ALL").
  const storedChatbotIds = localStorage.getItem('chatbot_ids');
  const allIds = storedChatbotIds ? JSON.parse(storedChatbotIds) : [];
  const chosenId = localStorage.getItem('selected_chatbot_id') || 'ALL';

  // 5. Fetch CRM data + conversations
  useEffect(() => {
    const fetchCRMData = async () => {
      setLoading(true);
      try {
        // 5a. CRM
        const crmRes = await axios.get(`${API_URL}/crm`);
        const allCRM = crmRes.data || [];

        // If user selected "ALL", we keep only those whose "chatbot_id" is in `allIds`.
        // If user selected a single ID, we keep only that one.
        let finalCRM;
        if (chosenId === 'ALL') {
          finalCRM = allCRM.filter((entry) => allIds.includes(entry.chatbot_id));
        } else {
          finalCRM = allCRM.filter((entry) => entry.chatbot_id === chosenId);
        }

        // 5b. Conversations
        const token = localStorage.getItem('token');
        if (!token) {
          setCrmData([]);
          setFilteredData([]);
          setConversations([]);
          setLoading(false);
          return;
        }

        // We do the same for conversations: pass a comma-separated list if "ALL"
        let joinIds = '';
        if (chosenId === 'ALL') {
          joinIds = allIds.join(',');
        } else {
          joinIds = chosenId;
        }

        const convRes = await axios.get(`${API_URL}/conversations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { chatbot_id: joinIds },
        });
        const allConversations = convRes.data || [];

        setCrmData(allCRM);
        setFilteredData(finalCRM);
        setConversations(allConversations);
      } catch (error) {
        console.error('Error fetching CRM or conversation data:', error);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch if we have at least one ID
    if (allIds.length > 0) {
      fetchCRMData();
    } else {
      setLoading(false);
    }
  }, [chosenId]); // re-run if user changes chatbot selection

  // 6. Calculate statistics
  const calculateStatistics = () => {
    // 6a. Filter out 'unregistered' purchase states
    const validData = filteredData.filter(
      (entry) => entry.madepurchase !== 'unregistered'
    );

    // 6b. Totals
    const totalVisitors = validData.length;
    const totalPurchases = validData.filter(
      (entry) => entry.madepurchase === 'true' || entry.madepurchase === true
    ).length;

    // 6c. Identify user IDs who truly used the chatbot
    const userIDsWhoChatted = new Set(conversations.map((c) => String(c.user_id)));

    // 6d. "Clicked" stats
    const totalClickedChatbot = validData.filter(
      (entry) => entry.usedchatbot === 'true' || entry.usedchatbot === true
    ).length;
    const clickedChatbotPurchases = validData.filter(
      (entry) =>
        (entry.usedchatbot === 'true' || entry.usedchatbot === true) &&
        (entry.madepurchase === 'true' || entry.madepurchase === true)
    ).length;

    // 6e. "Actually used" stats
    const totalActuallyUsedChatbot = validData.filter((entry) => {
      const idToCheck = String(entry.websiteuserid || entry.user_id);
      return userIDsWhoChatted.has(idToCheck);
    }).length;
    const actuallyUsedChatbotPurchases = validData.filter((entry) => {
      const idToCheck = String(entry.websiteuserid || entry.user_id);
      return (
        userIDsWhoChatted.has(idToCheck) &&
        (entry.madepurchase === 'true' || entry.madepurchase === true)
      );
    }).length;

    // 6f. Conversion rates
    const conversionRate =
      totalVisitors > 0
        ? ((totalPurchases / totalVisitors) * 100).toFixed(2)
        : '0.00';
    const clickedChatbotConversionRate =
      totalClickedChatbot > 0
        ? ((clickedChatbotPurchases / totalClickedChatbot) * 100).toFixed(2)
        : '0.00';
    const actuallyUsedChatbotConversionRate =
      totalActuallyUsedChatbot > 0
        ? ((actuallyUsedChatbotPurchases / totalActuallyUsedChatbot) * 100).toFixed(2)
        : '0.00';

    // 6g. Non-clicked conversion
    const nonClicked = totalVisitors - totalClickedChatbot;
    const nonClickedPurchases = totalPurchases - clickedChatbotPurchases;
    const nonClickedConversionRate =
      nonClicked > 0
        ? ((nonClickedPurchases / nonClicked) * 100).toFixed(2)
        : '0.00';

    return {
      totalVisitors,
      totalPurchases,
      totalClickedChatbot,
      clickedChatbotPurchases,
      totalActuallyUsedChatbot,
      actuallyUsedChatbotPurchases,
      conversionRate,
      clickedChatbotConversionRate,
      actuallyUsedChatbotConversionRate,
      nonClickedConversionRate,
    };
  };

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Skeleton active />
      </div>
    );
  }

  // If no chatbot IDs exist at all, show no data
  if (allIds.length === 0) {
    return <div>{t('noData')}</div>;
  }

  const stats = calculateStatistics();

  return (
    <div className="h-full flex w-full lg:mt-0 mt-10 lg:rounded-3xl">
      {/* Left Sidebar */}
      <div className={`max-w-[348px] rounded-3xl bg-[#F9FBFC] h-full flex mt-10 lg:mt-0 flex-col gap-y-4 px-6 py-8 w-full`}>
        <h2 className="text-[#212023] leading-0 mb-2 text-2xl font-medium">{t('sidebarTitle')}</h2>
        {/* <p>{t('sidebarSubtitle')}</p> */}

        <button
          onClick={() => setView('data')}
          className={view === 'data' ? 'text-white border bg-[#216384] rounded-3xl py-3' : 'py-3 border border-[#dbdbdb] rounded-3xl text-black'}
        >
          {t('rawData')}
        </button>
        <button
          onClick={() => setView('stats')}
          className={view === 'stats' ? 'text-white border bg-[#216384] rounded-3xl py-3' : 'py-3 border border-[#dbdbdb] rounded-3xl text-black'}
        >
          {t('stats')}
        </button>
      </div>

      {/* Main Area */}
      <div className="flex flex-col justify-center items-start px-5 lg:pl-20 overflow-y-auto w-full bg-[#EEEFFA] rounded-3xl lg:w-auto lg:flex-grow "> {/* Added lg:w-auto & lg:flex-grow */}
        {view === 'data' ? (
          filteredData.length === 0 ? (
            <div style={{ margin: '1em' }}>{t('noData')}</div>
          ) : (
            <div className="overflow-auto mb-5 w-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#E5E6F3]">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('visitedAt')}</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('usedChatbot')}</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('purchaseMade')}</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('chatbotID')}</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('userID')}</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredData.map((entry, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {new Date(entry.created_at).toLocaleString('da-DK', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {entry.usedchatbot === 'true' || entry.usedchatbot === true
                          ? t('yes')
                          : t('no')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {entry.madepurchase === 'true' || entry.madepurchase === true
                          ? t('yes')
                          : entry.madepurchase === 'false'
                            ? t('no')
                            : ''}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{entry.chatbot_id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{entry.websiteuserid || entry.user_id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : (
          <div className="border-[3498db] border-2 rounded-3xl p-4">
            <strong>
              <h3 className="text-2xl">{t('stats')}</h3>
            </strong>

            {/* Example: Show a few key stats. Customize as needed. */}
            <div className="my-2 text-lg">
              <strong>{t('totalVisitors')}:</strong> {stats.totalVisitors}
            </div>

            {/* 
              Uncomment these if you want them visible or
              adapt them to your layout:
              
            <div className="my-2 text-lg">
              <strong>{t('totalPurchases')}:</strong> {stats.totalPurchases}
            </div>
            <div className="my-2 text-lg">
              <strong>{t('chatbotUsers')}:</strong> {stats.totalClickedChatbot}
            </div>
            <div className="my-2 text-lg">
              <strong>{t('chatbotPurchases')}:</strong> {stats.clickedChatbotPurchases}
            </div>
            <div className="my-2 text-lg">
              <strong>{t('actuallyUsed')}:</strong> {stats.totalActuallyUsedChatbot}
            </div>
            <div className="my-2 text-lg">
              <strong>{t('actuallyUsedPurchases')}:</strong> {stats.actuallyUsedChatbotPurchases}
            </div>
            */}

            <div className="my-2 text-lg">
              <strong>{t('convRate')}:</strong> {stats.conversionRate}%
            </div>

            {/* 
              <div className="my-2 text-lg">
                <strong>{t('chatbotConvRate')}:</strong> {stats.clickedChatbotConversionRate}%
              </div>
            */}
            <div className="my-2 text-lg">
              <strong>{t('actuallyUsedConvRate')}:</strong> {stats.actuallyUsedChatbotConversionRate}%
            </div>
            {/* 
              <div className="my-2 text-lg">
                <strong>{t('nonChatbotConvRate')}:</strong> {stats.nonClickedConversionRate}%
              </div>
            */}
          </div>
        )}
      </div>
    </div>
  );
}

export default CRM;
