const StatsCard = ({ image, value, label, isLast }) => {
  return (
    <div
      className={`flex flex-col items-start bg-white border border-[#DBDCEC] rounded-3xl p-4 gap-y-2 ${
        isLast ? 'lg:items-start lg:text-start items-center text-center' : ''
      } h-full`}
    >
      <img src={image} alt={label} className="w-4 h-4 mb-2" />
      <h1 className="text-base font-medium mb-0">{value}</h1>
      <span className="text-sm font-light opacity-60 text-[#212023]">{label}</span>
    </div>
  );
};

export default StatsCard;