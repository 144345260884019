import { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const FilterDropdown = ({ t, setFejlstatusFilter, setCustomerRatingFilter, setEmneFilter, emneOptions, fejlstatusFilter, customerRatingFilter, emneFilter }) => {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = (e) => {
    e.domEvent.stopPropagation();
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" className='text-xs'>
        <label className="block mb-2">
          {t('bugStatus')}:
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item className='capitalize' onClick={() => setFejlstatusFilter("")}>{t('All')}</Menu.Item>
                <Menu.Item className='capitalize' onClick={() => setFejlstatusFilter("ingen")}>{t('noStatus')}</Menu.Item>
                <Menu.Item className='capitalize' onClick={() => setFejlstatusFilter("rapporteret")}>{t('reported')}</Menu.Item>
                <Menu.Item className='capitalize' onClick={() => setFejlstatusFilter("løst")}>{t('resolved')}</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <button className="w-full p-2 border h-[48px] text-xs flex justify-between items-center border-[#DBDCEC] rounded-lg text-left ant-dropdown-link" onClick={e => e.preventDefault()}>
              {fejlstatusFilter ? t(fejlstatusFilter) : t('all')} <DownOutlined />
            </button>
          </Dropdown>
        </label>
      </Menu.Item>
      <Menu.Item key="2">
        <label className="block mb-2">
          {t('customerRating')}:
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => setCustomerRatingFilter("")}>{t('all')}</Menu.Item>
                <Menu.Item onClick={() => setCustomerRatingFilter("1")}>1</Menu.Item>
                <Menu.Item onClick={() => setCustomerRatingFilter("2")}>2</Menu.Item>
                <Menu.Item onClick={() => setCustomerRatingFilter("3")}>3</Menu.Item>
                <Menu.Item onClick={() => setCustomerRatingFilter("4")}>4</Menu.Item>
                <Menu.Item onClick={() => setCustomerRatingFilter("5")}>5</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <button  className="w-full p-2 border h-[48px] text-xs flex justify-between items-center border-[#DBDCEC] rounded-lg text-left ant-dropdown-link" onClick={e => e.preventDefault()}>
              {customerRatingFilter ? customerRatingFilter : t('all')} <DownOutlined />
            </button>
          </Dropdown>
        </label>
      </Menu.Item>
      <Menu.Item key="3">
        <label className="block mb-2">
          {t('Topic')}:
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => setEmneFilter("")}>{t('all')}</Menu.Item>
                {emneOptions.map((emne) => (
                  <Menu.Item key={emne} onClick={() => setEmneFilter(emne)}>
                    {emne}
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
             <button  className="w-full text-xs p-2 border h-[48px] flex justify-between items-center border-[#DBDCEC] rounded-lg text-left ant-dropdown-link" onClick={e => e.preventDefault()}>
              {emneFilter ? emneFilter : t('all')} <DownOutlined />
            </button>
          </Dropdown>
        </label>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      onVisibleChange={(flag) => setVisible(flag)}
      visible={visible}
    >
      <button className="flex text-[#212023] text-xs font-light justify-end lg:justify-between items-center rounded-full py-1 pl-4 pr-1 w-full h-[48px] lg:bg-[#EEEFFA]">
        <span className='lg:block hidden capitalize'>{t('filters')}</span>
        <div className='h-10 w-10 flex justify-center items-center rounded-full bg-gradient-to-b from-white to-[#EDEEF9] shadow-[0px_2px_2px_0px_#0000001F]'>
          <img src='/svgs/filter.svg' alt='...' className='h-[18px] w-[18px]' />
        </div>
      </button>
    </Dropdown>
  );
};

export default FilterDropdown;